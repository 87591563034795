.admin {
  .profile-content {
    display: block;
  }

  .profile-title {
    font-weight: 600;
    font-size: 14px;
  }

  .schedule {
    background: #d6ecff;
    color: #3d4eac;
    border-radius: 10px;
    padding: 3px 7px;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
  }

  .schedule.ended {
    color: #4f566b;
    background: #e3e8ee;
  }

  .muted {
    opacity: 0.6;
  }
}
