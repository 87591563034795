.diary-panel {
  min-height: 170px;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;

  .table {
    transition: flex 500ms ease-in-out;
    margin-bottom: 0;

    .dark-mode & {
      color: $snow-white;
      background: $grey-7;
      border: 1px solid $grey-8;
    }
  }

  .table > :not(caption) > * > * {
    padding: 8px 8px 8px 16px;
  }

  .table .no-left-padding {
    padding: 8px 16px 8px 0;
  }

  .diary-group-row {
    background: $grey-1;
    border-bottom: 1px solid $grey-2;
    padding: 0.2rem 0.65rem;

    .dark-mode & {
      background: $grey-8;
      border-bottom: 1px solid $grey-7;
      color: $grey-3;
    }
  }

  .diary-group {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    .diary-group-title-container {
      display: inline-flex;
      width: inherit;
    }

    .diary-group-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.2px;
    }

    .diary-group-macros {
      width: inherit;
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      padding-right: 16px;
    }

    .btn-collapse {
      background: transparent;
      border: none;
      padding: 2px 12px;
      color: $jungle-green;

      .dark-mode & {
        color: $snow-white;
      }
    }

    .diary-group-collapsed {
      transform: rotate(0deg);
      transition: 150ms ease-in-out;
    }

    .diary-group-expanded {
      transform: rotate(180deg);
      transition: 150ms ease-in-out;
    }

    .btn-collapse-container {
      border-radius: $border-radius-lg;
    }

    .btn-collapse-container:hover {
      background: $grey-3;
    }
  }

  .diary-group-selected {
    background: $grey-2;

    .dark-mode & {
      background: #343643;
    }
  }

  .select {
    background: $teal-1 !important;

    .dark-mode & {
      background: $teal-5 !important;
    }
  }

  .dragging {
    cursor: move;
    background-color: $grey-1;
    opacity: 0.4;
  }

  .even-row {
    background-color: $snow-white;

    .dark-mode & {
      background-color: $grey-6;
    }
  }

  .diary-time {
    text-align: left;
    padding-left: 24px;
  }

  i.icon-custom-recipe {
    color: $jungle-green;
  }

  i.icon-supplement {
    color: $lupine-purple;
  }

  span.material-icons-outlined {
    font-size: 18px;
    vertical-align: middle;
    color: $blue-6;
  }

  .empty-diary {
    height: 170px;
    font-weight: bold;
    font-size: 16px;
    background: $white;
    cursor: default;
    padding: 16px;

    .dark-mode & {
      background: $grey-6;
    }

    .even-row {
      background: $white;

      .dark-mode & {
        background: $grey-6;
      }
    }

    td {
      padding: 0;
      vertical-align: top;
    }
  }
  
  .table-top-border {
    border-radius: $border-radius-lg;
    border-top: 1px $raised-border-color-light solid;
  }
}

.developer-ad {
  background-color: $teal-1;
  border: 1px solid $teal-4;
}
