.target-editor-dialog {
  width: 100%;

  .bar-label-annotation {
    font-family: "Manrope", sans-serif;
    color: $dark-raisin;
  }

  .targets-container {
    display: flex;
    flex-direction: column;
  }

  .target-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 8px 0;
  }

  .number-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }

  .top-sources-container {
    margin: 16px 0;
    border: 1px solid $grey-2;
    border-radius: 10px;
  }

  .top-sources-title {
    font-weight: bold;
    font-size: 16px;
    background: $grey-2;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    padding: 8px;
  }

  .top-source-inner-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
  }

  .top-source-arrow-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 80%;

    i {
      margin: 0 4px 0 0;
      cursor: pointer;
      transition: 100ms ease-in-out;
    }
  }

  .top-source-ingredient-row .top-source-food-name {
    margin: 0 0 0 32px;
  }
}
