@import "_text-box.scss";

.dropdown-btn {
  @extend .text-box;
  position: relative;
  padding-right: 34px !important;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-height: 42px;

  &:after {
    position: absolute;
    right: 16px;
    top: 18px;
  }
}

.dropdown-btn-medium {
  @extend .dropdown-btn;
  min-height: 30px !important;
  padding: 3px 16px !important;

  &:after {
    position: absolute;
    right: 8px;
    top: 12px;
  }
}

.dropdown-toggle:after {
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  color: $grey-6;

  .disabled-input & {
    color: $grey-5;
  }
}

.dropdown-menu {
  width: inherit;
  border-radius: 4px;
  border: 1px solid $grey-6;
  background: $snow-white;
  color: $dark-raisin;

  .dark-mode & {
    background: $raised-container-bg-dark;
    border: 1px solid $raised-border-color-dark !important;
    color: $snow-white;
  }

  .input-light & {
    background-color: $white;
  }
}

.dropdown-item {
  cursor: pointer;
  color: $dark-raisin;

  &:hover {
    background-color: $grey-1;
  }

  &:active {
    color: $dark-raisin;
    background-color: $teal-1 !important;
  }

  .dark-mode & {
    color: $snow-white;
  }
}

.scrollable-dropdown {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $teal-2;

    &:hover {
      background-color: $teal-4;
    }
  }
}

.dropdown-separator {
  margin: 0;
  border-top: 1px solid $grey-6;
}