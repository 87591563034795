
@mixin large-mixin($parent, $width, $height) {
  &#{$parent} .form-check-input {
    width: $width;
    height: $height;
    margin-top: -1px;
    margin-right: 12px;
  }
}


@mixin medium-mixin($parent, $width, $height) {
  &#{$parent} .form-check-input {
    width: $width;
    height: $height;
    margin-right: 8px;
  }
}

.switch-large {
  @include large-mixin('.form-switch' ,56px, 28px);
}

.input-large {
  @include large-mixin('.form-check', 28px, 28px);
}

.input-medium {
  @include medium-mixin('.form-check', 20px, 20px);
}

.form-check {
    &.no-label {
        margin-bottom: 0;

        .form-check-input {
            margin-right: 0;
        }
    }
}
