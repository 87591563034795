@font-face {
  font-family: 'crono-icon';
  src: url('/fonts/crono-icon.ttf?ofmigu') format('truetype'),
  url('/fonts/crono-icon.woff?ofmigu') format('woff'),
  url('/fonts/crono-icon.svg?ofmigu#crono-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'crono-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wordmark {
  letter-spacing: -0.8px;
  font-size: 26px;
  font-weight: 700;
  margin-left: 5px;
}

.icon-check-circle-fill:before {
  content: "\e91c";
}

.icon-check-circle:before {
  content: "\e91d";
}

.icon-dashboard-icon:before {
  content: "\e911";
  color: #fff;
}

.icon-settings-icon:before {
  content: "\e912";
  color: #fff;
}

.icon-custom-recipe:before {
  content: "\e91e";
}

.icon-meals:before {
  content: "\e91f";
  font-size: 20px;
  color: $blue-6;
}

.icon-arrow-left-circle:before {
  content: "\e909";
}

.icon-arrow-left:before {
  content: "\e90a";
}

.icon-arrow-right-circle:before {
  content: "\e90b";
}

.icon-arrow-right:before {
  content: "\e90c";
}

.icon-bell-fill:before {
  content: "\e90d";
}

.icon-x-big:before {
  content: "\e90e";
}

.icon-biometric-custom-add:before {
  content: "\e90f";
}

.icon-biometric:before {
  content: "\e910";
}

.icon-biometrics-categories:before {
  content: "\e913";
}

.icon-calendar:before {
  content: "\e914";
}

.icon-camera:before {
  content: "\e915";
}

.icon-chevron-down:before {
  content: "\e917";
}

.icon-chevron-left:before {
  content: "\e918";
}

.icon-chevron-right:before {
  content: "\e919";
}

.icon-chevron-up:before {
  content: "\e91a";
}

.icon-circle-add:before {
  content: "\e91b";
}

.icon-download:before {
  content: "\e928";
}

.icon-exclamation-circle:before {
  content: "\e929";
}

.icon-exercise:before {
  color: $blue-6;
  content: "\e92a";
}

.icon-file-arrow-down:before {
  content: "\e937";
}

.icon-filter:before {
  content: "\e938";
}

.icon-gear:before {
  content: "\e939";
}

.icon-link:before {
  content: "\e93f";
}

.icon-lock:before {
  content: "\e940";
}

.icon-notes:before {
  color: #f6b30e;
  content: "\e941";
}

.icon-pencil-square:before {
  content: "\e958";
}

.icon-question-circle:before {
  content: "\e959";
}

.icon-refresh:before {
  content: "\e95a";
}

.icon-search:before {
  content: "\e95b";
}

.icon-shield-lock:before {
  content: "\e961";
}

.icon-star-fill:before {
  content: "\e962";
}

.icon-star:before {
  content: "\e963";
}

.icon-stopwatch-fill:before {
  content: "\e964";
}

.icon-stopwatch:before {
  content: "\e965";
}

.icon-supplement:before {
  content: "\e966";
}

.icon-three-dots-vertical:before {
  content: "\e96f";
}

.icon-trash-fill:before {
  content: "\e970";
}

.icon-upload:before {
  content: "\e975";
}

.icon-x-circle-fill:before {
  content: "\e976";
}

.icon-x-circle:before {
  content: "\e977";
}

.icon-x-small:before {
  content: "\e978";
}

.icon-logomark:before {
  content: "\e907";
  color: $crono-orange;
}

.icon-logomark-gold:before {
  color: $honey-gold;
}

.icon-logomark-pro:before {
  color: $jade-lake;
}

.icon-logomark-corn:before {
  color: #2895f7;
}

.icon-logomark-developer:before {
  color: $fire-weed;
}

.icon-wordmark:before {
  content: "\e908";

  .dark-mode & {
    color: $snow-white;
  }
}

.icon-more:before {
  content: "\e920";
  color: $snow-white;
}

.icon-about:before {
  content: "\e900";
}

.icon-diary:before {
  content: "\e901";
}

.icon-foods {
  font-size: 20px;
}

.icon-foods:before {
  content: "\e902";
  color: $snow-white;
}

.icon-help:before {
  content: "\e903";
  color: $snow-white;
}

.icon-plans:before {
  content: "\e904";
  color: $snow-white;
}

.icon-dashboard:before {
  content: "\e911";
  color: $snow-white;
}

.icon-settings:before {
  content: "\e905";
  color: $snow-white;
}

.icon-trends:before {
  content: "\e906";
  color: $snow-white;
}

.icon-food:before {
  content: "";
  display: block;
  width: 19px;
  height: 18px;
  background-size: 19px 18px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44.566' height='51.074' viewBox='0 0 44.566 51.074'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='44.566' height='51.074' transform='translate(0 0)' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 0)' clip-path='url(%23a)'%3E%3Cpath d='M28.638 4.969a4.258 4.258 0 0 0-2.624 1.684A9.2 9.2 0 0 0 24.8 12.081a7.724 7.724 0 0 1-2.517.628 8.036 8.036 0 0 1-2.613-.664 16.324 16.324 0 0 1 .375-4.239c-1.7.458-4.207.859-6.079-.051C10.877 6.248 9.534 1.166 9.527 1.131A15.168 15.168 0 0 1 14.763 0 6.213 6.213 0 0 1 17.479.55c1.722.84 2.9 2.789 3.607 4.361A10.686 10.686 0 0 1 21.8 3.718 9.289 9.289 0 0 1 25.415.665a3.122 3.122 0 0 1 4.4 2.856 1.468 1.468 0 0 1-1.182 1.448' fill='%2314b255'/%3E%3Cpath d='M38.9 44.26c-2.27 3.258-4.944 6.945-8.484 6.81-3.431-.135-4-2.18-8.134-2.18s-4.7 2.046-8.134 2.18c-3.543.135-6.217-3.552-8.487-6.81-4.62-6.65-8.16-18.9-3.4-27.134A13.2 13.2 0 0 1 13.4 10.371c2.254-.035 4.441.968 6.278 1.677a8.042 8.042 0 0 0 2.613.664 7.724 7.724 0 0 0 2.517-.628c1.856-.709 4.078-1.747 6.374-1.712a13.208 13.208 0 0 1 11.135 6.755c4.75 8.235 1.21 20.483-3.413 27.133' fill='%23cd0a01'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-add-biometric:before {
  content: "";
  display: block;
  width: 19px;
  height: 18px;
  background-size: 19px 18px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='36' viewBox='0 0 39 36'%3E%3Cpath d='M19.5 36C9.015 28.812 3.516 22.273 1.261 16.8-4.781 2.133 12.476-4.881 19.5 3.777 26.524-4.881 43.781 2.133 37.739 16.8 35.484 22.273 29.985 28.812 19.5 36Z' transform='translate(0 0)' fill='%23E34683'/%3E%3Cg transform='translate(5.083 9.075)'%3E%3Cpath d='M57.671 71.105h-5.24a.571.571 0 0 0-.542.31l-2.343 5.034L46.061 64.39a.581.581 0 0 0-.577-.39h0a.6.6 0 0 0-.577.38L40.833 78.44 38.29 68.033a.579.579 0 0 0-.542-.4.593.593 0 0 0-.589.34l-1.943 4.934H30.589a.507.507 0 1 0 0 1H35.64a.583.583 0 0 0 .553-.34l1.378-3.513L40.2 80.812a.573.573 0 0 0 .565.4h.012a.59.59 0 0 0 .577-.39L45.484 66.6 48.828 78.22a.559.559 0 0 0 .53.38.61.61 0 0 0 .589-.31l2.885-6.184h4.84a.507.507 0 1 0 0-1Z' transform='translate(-29.713 -63.71)' fill='%23fff'/%3E%3Cpath d='M45.521 63.75a.863.863 0 0 1 .853.6l3.273 11.322 2.019-4.338a.858.858 0 0 1 .8-.48h5.24a.795.795 0 1 1 0 1.581H53.05l-2.807 6.018a.9.9 0 0 1-.859.476.84.84 0 0 1-.795-.591l-3.07-10.664L41.663 80.944a.882.882 0 0 1-.852.6H40.8a.863.863 0 0 1-.843-.618l-2.412-9.881L36.5 73.71a.865.865 0 0 1-.821.527H30.626a.795.795 0 1 1 0-1.581h4.433l1.871-4.751a.871.871 0 0 1 .866-.523h.01a.86.86 0 0 1 .8.614l0 .006 2.289 9.365 3.778-13.037A.883.883 0 0 1 45.521 63.75Zm4 13.555-3.7-12.8a.3.3 0 0 0-.3-.176.316.316 0 0 0-.3.177L40.846 79.593l-2.8-11.446a.3.3 0 0 0-.28-.184.314.314 0 0 0-.307.157l-2.015 5.117H30.626c-.167 0-.3.094-.3.21s.135.21.3.21h5.051a.31.31 0 0 0 .283-.148l0-.009 1.708-4.355 2.84 11.637a.292.292 0 0 0 .287.18h.012a.309.309 0 0 0 .3-.186l4.408-15.167 3.618 12.57a.276.276 0 0 0 .265.171.335.335 0 0 0 .319-.145l2.962-6.35h5.021c.167 0 .3-.094.3-.21s-.135-.21-.3-.21h-5.24a.3.3 0 0 0-.279.137l0 .007Z' transform='translate(-29.75 -63.75)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}
