.button-panel-btn {
  display: inline-flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: $dark-raisin;
  padding: 6px 10px;
  margin-right: 4px;
  text-align: left;
  border-radius: 10px;
  border: 1px solid transparent;
  background: $white;
  outline: none;

  .dark-mode & {
    border: 1px solid transparent;
    background: $raised-container-bg-dark;
    color: $grey-2;
  }
}

@media (max-width: 1200px) {
  .button-panel-btn {
    padding: 9px 8px;
    margin-right: 12px;
  }
}

.button-panel-btn span {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}

.button-panel-btn i {
  margin-right: 12px;
}

.button-panel-btn:hover,
.button-panel-btn:active,
.button-panel-btn:focus {
  background: $grey-1;

  .dark-mode & {
    background: $grey-7;
    color: $snow-white;
  }
}

.button-panel-btn:disabled {
  cursor: default;
  background: $white;
  opacity: 0.4;

  .dark-mode & {
    background: $raised-container-bg-dark;
  }
}

.button-panel-menu {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

i.button-panel-menu {
  font-size: 30px;

  .dark-mode & {
    color: $snow-white;
  }
}

i.button-panel-menu:hover {
  opacity: 0.7;
}

.divider {
  width: 2px;
  height: 40px;
  border-right: 2px solid $grey-3;
}

.collapse-diary {
  height: 0 !important;
  min-height: 0 !important;
  overflow: hidden !important;
}