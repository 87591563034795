@import "_typography.scss";

.pretty-dialog {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 3px 20px #7779861A;
  z-index: 10001;
  transition: opacity 100ms ease-in;

  &:has(.popup-scroll-inner) {
    padding: 0;
    overflow: hidden !important;
  }

  .dark-mode & {
    background-color: $modal-dark-mode-bg;
    border: 1px solid $modal-dark-mode-border;
  }

  .prettyDialogChoices {
    display: flex;
    justify-content: flex-end;
  }

  .titlebar-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .titlebar-title {
    @extend .container-title;
    display: inline-block;
  }

  .title-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .titlebar-cancelbox {
    position: relative;
    bottom: 12px;
    left: 12px;
    cursor: pointer;

    & * {
      color: $jungle-green;
      font-size: 16px;
      font-weight: 800;
      width: 16px;
      height: 16px;

      .dark-mode & {
        color: $teal-2;
      }
    }
  }

  .cancel {
    margin-right: 20px;
  }

  .popupContent {
    overflow: visible;
    padding: 16px;

    &:has(.popup-scroll-inner) {
      padding: 0px;
    }
  }

  .top-slide-out {
    transition: opacity 100ms ease-out;
  }

  .popup-scroll-inner {
    padding: 24px;
    overflow-y: auto;
  }
}

