.sidebar-pro {

  .btn:focus {
    box-shadow: none;
  }

  .btn-toggle:hover i:before,
  .btn-toggle.active i:before,
  .btn-sidebar:hover i:before,
  .btn-sidebar.active i:before,
  .btn-sidebar.active .nav-title {
    color: $jade-lake !important;
  }

  .btn-sidebar:hover {
    color: $jade-lake !important;
  }

  .btn-toggle:hover {
    color: $jade-lake !important;
  }

  .btn-toggle:hover::after,
  .btn-toggle.active::after {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb%2868,208,123%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  }

  .nav-item .active {
    color: $jade-lake !important;
  }

  .sidebar-nav-container a:hover {
    color: $jade-lake !important;
  }

  .lightswitch-label {
    .text-primary {
      color: $jade-lake !important;
    }
  }

  .lightswitch-container {
    .form-check-input:checked {
      background-color: $jade-lake;
      border-color: $jade-lake;
    }

    .form-check-input:focus {
      box-shadow: 0 0 0 0.25rem rgb(68, 208, 123, 15%);
    }

    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b2ecc8'/%3e%3c/svg%3e");
    }
  }
}

body[data-sidebar-compact=true] .sidebar-pro .sidebar-nav-item:hover a.active i,
body[data-sidebar-compact=true] .sidebar-pro .sidebar-nav-item:hover a.active .nav-title-with-children {
  color: $jade-lake;
}

body[data-sidebar-compact=false] .pro-sidebar-client {
  background-color: $grey-8;
}

.client-name {
  max-width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-unread {
  width: 100%;
  justify-content: flex-end;
}
