.energy-summary-totals {
  width: 450px;
  z-index: 9999;
  position: absolute;
  left: -50px;
  bottom: 110px;
  padding: 16px;
  font-size: 14px;

  .energy-summary-totals-container {
    border: 1px solid $raised-border-color-light;
    border-radius: 10px;

    .dark-mode & {
      border: 1px solid $raised-border-color-dark;
    }
  }

  .totals-row:last-child {
    border-bottom: none !important;
  }

  .totals-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    margin: 0;
    border-bottom: 1px solid $raised-border-color-light;

    .dark-mode & {
      border-bottom: 1px solid $raised-border-color-dark;
    }
  }

  .hide-bottom-border {
    border-bottom: none !important;
  }

  .totals-title {
    font-weight: bold;
    text-align: left;
    width: 200px;
  }

  .totals-title-normal {
    font-weight: normal !important;
  }

  .totals-value {
    text-align: right;
    width: 70px;
  }

  .totals-percent {
    width: 100px;
    text-align: right;
  }
}

.remaining-container {
  bottom: 150px !important;
}
