.snack-bar {
  display: flex;
  position: fixed;
  background-color: $dark-raisin;
  color: $white;
  padding: 8px 10px 8px 25px;
  align-items: center;
  width: 450px;
  border-radius: 50px;

  &.center-bottom {
    left: 50%;
    bottom: 32px;
    transform: translate(-50%, 0);
  }

  &.success {
    background-color: $jade-lake;
    color: $dark-raisin;
    padding-right: 24px;
  }
}