@mixin make-scrollable() {
  &:has(> .container-scroll-inner) {
    padding: 0;
    overflow: hidden;
  }

  & > .container-scroll-inner {
    padding: 24px;
    overflow-y: auto;
  }
}

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.page-container {
  @extend .container;
  padding: 0 16px;
}

.fluid-page-container {
  @extend .container-fluid;
  padding: 0 24px;
}

.calendar-sidebar {
  flex: 0 0 350px;
}

@media (max-width: 992px) {
  .calendar-sidebar {
    display: none;
  }
}

.container-raised {
  @include make-scrollable();
  border: 1px solid $grey-1;
  border-radius: 10px;
  background: $white;
  padding: 24px 24px;
  box-shadow: $shadow-default;

  .dark-mode & {
    border: 1px solid $dark-raisin;
    border-radius: 10px;
    background: $dark-raisin;
    box-shadow: $shadow-default;
  }
}

.box-shadow-standard {
  box-shadow: rgba($grey-6, 0.1) 0 3px 20px;
}

.box-shadow-inset {
  box-shadow: inset 0px 0px 20px rgba($grey-6, 0.1);
}

.container-raised-no-padding {
  border: 1px solid $grey-1;
  border-radius: 10px;
  background: $white;
  box-shadow: $shadow-default;

  .dark-mode & {
    border: 1px solid $dark-raisin;
    border-radius: 10px;
    background: $dark-raisin;
    box-shadow: $shadow-default;
  }
}

/* Like container-raised, but reversed (dark inside, light outside ¯\_(ツ)_/¯ */
.reverse-container-raised {
  padding: 24px;
  background: $grey-1;
  border-radius: $border-radius-lg;

  .dark-mode & {
    background: $grey-8;
  }
}

.grouping-container {
  @include make-scrollable();
  border: 1px solid $grey-2;
  border-radius: 10px;
  background: $snow-white;
  padding: 24px;
}

.gold-gate {
  border-radius: 10px;
  background-color: $yellow-2;
  padding: 20px 24px;
}

.contact-support {
  color: $jungle-green;
  text-decoration: underline;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer !important;
}

.help-link-button {
  margin-left: 0.5rem;
  color: $jungle-green;

  .dark-mode & {
    color: $teal-2;
  }
}

.help-link-button:hover {
  color: $jungle-green;
  opacity: 0.7;
}

.top-slide-in {
  transition: top 80ms ease-in;
}

.top-slide-out {
  transition: top 100ms ease-out;
}

/* Simple dark logo header used in all the non-GWT pages (landing, validation, login, etc...) */
.logo-header {
  width: 310px;
  height: 88px;
  background-color: $dark-raisin;
  background-image: url("https://cdn1.cronometer.com/brand2/Cronometer_MainLogo_FloralWhite_Mandarin.svg");
  background-repeat: no-repeat;
  background-position-x: 36px;
  background-position-y: 19px;
  background-size: 232px 49px;
}

.light-flourish-body {
  width: 100%;
  text-align: center;
  background-color: var(--floral-white);
  background-image: url("https://cdn1.cronometer.com/brand2/assets/DotPatternLarge.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .light-flourish-body {
    background-image: url("https://cdn1.cronometer.com/brand2/assets/DotPatternSmall.svg");
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}

@mixin create-border-radius-class($radius-name, $border-radius) {
  $radius-class: ".rounded-" + $radius-name;
  #{$radius-class} {
    border-radius: valid-radius($border-radius);
  }
}

@include create-border-radius-class("xl", $border-radius-xl);
@include create-border-radius-class("lg", $border-radius-lg);
@include create-border-radius-class("md", $border-radius-md);
@include create-border-radius-class("sm", $border-radius-sm);

@mixin create-border-thickness-class($thickness-name, $border-thickness) {
  $thickness-class: ".border-" + $thickness-name;
  #{$thickness-class} {
    border: $border-thickness solid;
  }

  $bottom-thickness-class: ".border-bottom-" + $thickness-name;
  #{$bottom-thickness-class} {
    border-bottom: $border-thickness solid;
  }

  $top-thickness-class: ".border-top-" + $thickness-name;
  #{$top-thickness-class} {
    border-top: $border-thickness solid;
  }

  $left-thickness-class: ".border-left-" + $thickness-name;
  #{$left-thickness-class} {
    border-left: $border-thickness solid;
  }

  $right-thickness-class: ".border-right-" + $thickness-name;
  #{$right-thickness-class} {
    border-right: $border-thickness solid;
  }
}

@include create-border-thickness-class("thicc", $border-thickness-thicc);
@include create-border-thickness-class("thiccc", $border-thickness-thiccc);
@include create-border-thickness-class("thicccc", $border-thickness-thicccc);
@include create-border-thickness-class("thiccccc", $border-thickness-thiccccc);

.flex-one {
  flex: 1;
}

progress::-webkit-progress-value {
  background-color: $crono-orange !important;
}

progress::-moz-progress-bar {
  background-color: $crono-orange !important;
}

progress::-webkit-progress-bar {
  background-color: $grey-3;
}

progress {
  height: .5rem;
  color: $grey-3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.shadow-default {
  box-shadow: $shadow-default;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}