.pro-onboarding {
  .text-box {
    //Overwriting the default 'snow white' background color on text inputs to match design
    background-color: white;
  }

  #pro-terms {
    //Overwriting some styles in the LicenseAgreement dialog
    .container-description {
      font-weight: 400;

      a {
        font-weight: 700;
      }
    }

    .form-check-label {
      font-weight: 700;
    }
  }

  .credit-card {
    padding-bottom: 5px;
    border-bottom: 1px solid #E6E8F0;
  }

  .credit-card-element {
    padding: 20px;
    background: #FAFBFF;
    border: 1px solid #E6E8F0;
    border-radius: 3px;
  }

  .card-errors {
    margin: 20px;
    color: #D80C00;
  }

  .onboarding-text {
    font-size: 18px;
  }

  #cronometer-header {
    background-color: var(--raised-container-bg-dark);
    height: 56px;

    @include media-breakpoint-up(md) { // Applies from md breakpoint upwards
      height: 88px;
    }
  }

  #cronometer-header-image {
    box-sizing: border-box;
    padding: 13px;
    padding-left: 36px;

    @include media-breakpoint-up(md) { // Applies from md breakpoint upwards
      padding: 16px;
    }
  }

  .pro-text {
    font-size: 2.1rem;

    @include media-breakpoint-up(md) { // Applies from md breakpoint upwards
      font-size: 2rem;
    }
  }

  #trial-text {
    font-size: 1.9rem;
    font-weight: 700;
  }

  .text-center-md {
    text-align: left; //

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  .rounded-bottom-only {
    border-radius: 0 0 10px 10px;
  }

}

.min-width-modal {
  width: auto;
  @include media-breakpoint-up(md) {
    width: 567px;
  }
}

.work-sans {
  font-family: 'Work Sans', sans-serif;
}
