// Giving this file its own namespace so we don't cause conflicts
// Might be nice to do elsewhere in our .scss classes cause there are already conflict issues
.pro-messaging {

  // ProMessageMiniChat.ui.xml

  .bottom-container {
    width: 100%;
    background: $white;
    padding: 16px;

  }

  .text-bar-container {
    display: flex;
    align-items: flex-end;
    background: $grey-1;
    width: 100%;
    min-height: 46px;
    border-radius: 10px;
  }

  .text-box-container {
    width: 1056px;
    margin: 16px;
  }

  .small-chat {
    &.chat-container {
    }

    background-color: $white;
    position: fixed;
    bottom: 2px;
    right: 90px;
    width: 288px;
    box-shadow: 0 3px 6px $grey-3;
    border-radius: 16px 16px 0 0;

    .title-container {
      height: 48px;
      border-bottom: 1px solid $grey-3;
      position: relative;
      padding: 0 16px;
    }

    .scroll-panel {
      flex-grow: 1;
      position: static;
      max-height: 246px;
      width: 100%;
      padding: 0 16px;

      /* track */
      &::-webkit-scrollbar {
        width: 6px;
        background: none;
        border: none;
      }

      &::-webkit-scrollbar-thumb {
        background: $grey-3;
        border-radius: $border-radius-sm;
      }

    }

    .vertical-flex-container {
      display: flex;
      flex-direction: column;
      height: 324px;
    }

    .content {
      margin: 16px 16px 8px 16px;
      max-width: 180px;
      white-space: pre-wrap;
      word-break: break-word;
      font-size: 14px;
    }


  }

  .title-text {
    margin-left: 4px;
    font-weight: 600;
    text-align: left;
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .large-chat {
    &.chat-container {
      z-index: 10001;
    }

    .minimize, .close {
      font-size: 28px;
      cursor: pointer;
      color: $jungle-green;
      padding-left: 10px;

      &:hover {
        color: $teal-9;
      }
    }

    //height: 80%;
    background-color: $white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    border-radius: 16px 16px 16px 16px;

    .title-container {
      height: 48px;
      border-bottom: 1px solid $grey-3;
      position: relative;
      padding: 0 16px;
    }

    .scroll-panel {
      flex-grow: 1;
      position: static;
      max-height: 450px;
      width: 100%;
      padding: 0 16px;

      &::-webkit-scrollbar {
        width: 6px;
        background: none;
        border: none;
      }

      &::-webkit-scrollbar-thumb {
        background: $grey-3;
        border-radius: 3px
      }
    }

    .vertical-flex-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 16px;
    }

    .text-bar-container {

      display: flex;
      align-items: flex-end;
      background: $grey-1;
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      bottom: 0;
      padding: 0 14px 0 10px;

    }

    .content {
      margin: 16px 16px 8px 16px;
      max-width: 600px;
      white-space: pre-wrap;
      word-break: break-word;
      font-size: 16px;
    }


  }

  .notification-container {
    position: relative;
    margin-left: 8px;
  }

  .maximize {
    width: 80%;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: none;
    z-index: -1;
  }

  .maximize.enabled {
    cursor: pointer;
    z-index: 1;
  }


  .minimize-container, .close-container {
    display: flex;
  }

  .minimize, .close {
    font-size: 28px;
    cursor: pointer;
    color: $jungle-green;


    &:hover {
      color: $teal-9;
    }
  }

  .expand, .collapse {
    font-size: 28px;
    cursor: pointer;
    color: $jungle-green;

    &:hover {
      color: $teal-9;
    }
  }

  .collapse-container, .expand-container {
    cursor: pointer;
    color: $jungle-green;

    &:hover {
      color: $teal-9;
    }
  }

  .bottom-container {
    width: 100%;
    background: $white;
    padding: 16px;
  }

  .text-bar-container {
    display: flex;
    align-items: flex-end;
    background: $grey-1;
    width: 100%;
    min-height: 46px;
    border-radius: 10px;
  }

  .text-box-container {
    width: 100%;
    margin: 16px;
  }

  .send-message-button {
    margin: 0 16px 10px 0;
    color: $grey-6;

    &.enabled {
      color: $jungle-green;
      cursor: pointer;
    }
  }

  // ExpandingTextArea.ui.xml

  .expanding-container {
    position: relative;
    border: none;
    max-height: 72px;

    textarea, div {
      margin: 0;
      padding: 0;
      outline: 0;
      border: 0;
    }

    .mirror {
      visibility: hidden;
    }

    .expanding-text-area, .mirror {
      font-size: 14px;
      white-space: pre-wrap;
      word-wrap: break-word;
      min-height: 16px;
    }

    .expanding-text-area {
      background: $grey-1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      resize: none;
    }
  }

  // ProMessageContainer.ui.xml

  .message {
    text-align: left;
    margin: 5px 0;
    clear: both;

    &.sender {
      border-radius: 10px 10px 0 10px;
      background: $grey-1;
      float: right;
    }

    &.receiver {
      border-radius: 10px 10px 10px 0;
      background: $teal-1;
      float: left;
    }
  }


  .timestamp {
    float: right;
    font-size: 12px;
    margin: 0 16px 16px 16px;
  }

  .message-tip {
    margin: 16px 0;
    padding: 10px 16px;
    border-radius: 4px;
    background: $grey-1;
    text-align: center;
    clear: both;

    .tip-title-container {
      display: flex;
      justify-content: center;
    }

    .encryption-icon {
      color: $jungle-green;
      margin-right: 4px;
    }

    .decryption-error-icon {
      color: $crono-error;
      margin-right: 4px;
    }

    .title {
      font-weight: 700;
    }

    .body {
      font-size: 14px;
    }
  }

  .decryption-error {
    cursor: pointer;
  }

  // ProMessageChatButton.ui.xml

  .chat-button-container {
    display: flex;
    position: relative;
    border-radius: 6px;

    &.enabled {
      cursor: pointer;
    }

    &.enabled:hover {
      background: $grey-1;
    }
  }

  .enabled {
    .chat-button {
      color: $crono-orange;

      &:active {
        color: $orange-6;
      }
    }
  }

  .chat-button {
    padding: 6px 8px;
    color: $grey-4;
    font-size: 30px;
  }

  // proMessageUnreadNotification.ui.xml

  .unread-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    top: 2px;
    min-width: 14px;
    height: 14px;
    border-radius: 14px;
    font-size: 10px;
    padding: 0 3px;
    background-color: $grey-5;
    color: $white;

    &.large {
      font-size: 12px;
      font-weight: 600;
      background: $jade-lake;
      color: $dark-raisin;
      position: static;
      width: fit-content;
      border-radius: 18px;
      min-width: 22px;
      height: 19px;
      padding: 0 5px;

      .main-sidebar & {
        background-color: $crono-orange;
      }
    }
  }

  .unread-count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    min-width: 1em;
    border-radius: 50%;
    vertical-align: middle;
    line-height: 1em;
    font-weight: bold;
    box-sizing: content-box;

    &:before {
        content: '';
        float: left;
        width: auto;
        padding-bottom: 100%;
    }
  }

  .unread-icon-position {
    position: absolute;
    left: calc(100% - 8px);
    bottom: calc(100% - 12px);
  }

  .enabled {
    .unread-notification {
      background-color: $jungle-green;
    }
  }

  .bounce {
    animation: bounce 1s ease infinite;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-6px);
    }
    60% {
      transform: translateY(-4px);
    }
  }
}

