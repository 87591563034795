$diary-fast-overlap-offset: 6px;

.fast-diary-entry {
    display: flex;
    width: 100%;
    border-radius: $border-radius-lg $border-radius-lg 0px 0px;
    min-height: 35px;
    align-items: center;
    gap: $spacer;
    border-top: 1px $grey-1 solid;
    position: relative;
    top: $diary-fast-overlap-offset;
}

.fast-dark {
    background-color: $teal-1;
}

.fast-light {
    background-color: $teal-0;
}

.fast-entry-wrapper {
    min-height: 35px;
    display: flex;    
}

.fast-clock {
    margin-left: $spacer;
    display: flex;
}

.fast-diary-name-label {
    color: $dark-raisin;
    font-size: map-get($font-sizes, 7);
    font-weight: bold;
    margin-left: $spacer;
    word-break: break-all;
}

.fast-diary-elapsed-label {
    color: $dark-raisin;
    font-size: map-get($font-sizes, 7);
    font-weight: bold;
    margin-left: 3px;
}

.fast-diary-time-label {
    color: $dark-raisin;
    font-size: map-get($font-sizes, 7);
    font-weight: bold;
    display: flex;
}

.fast-diary-start-end-time-label {
    color: $jungle-green;
    font-size: map-get($font-sizes, 7);
    font-weight: bold;
    display: flex;
}

.fast-diary-start-end-label {
    color: $dark-raisin;
    font-size: map-get($font-sizes, 7);
    display: flex;
}


.fast-stop {
    margin-right: $spacer;
    display: flex;
    cursor: pointer;
    margin: auto;
}

.fast-diary-chevron {
    color: $jungle-green;
    margin-right: $spacer;
    display: flex;
    margin: auto;
}

.fast-diary-action-container {
    width: 40px;
}

.fast-diary-overlap {
    position: relative;
    top: $diary-fast-overlap-offset;
    height: 5px;
}
