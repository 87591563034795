.food-summary-panel {

  .food-search-name {
    font-size: 1.125rem;
  }

  div > .boxed-border-thingy {
    display: flex;
    border: 1px solid $grey-2;

    .dark-mode & {
      border: 1px solid $grey-5;
    }
  }

  .nutrient-listing-container {
    .nutrients-link {
      cursor: pointer;
      text-decoration: underline;
    }

    a {
      color: currentColor;
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }

    .nutrients-link:hover {
      text-decoration: none;
    }
  }

  .alt-info-container {
    background-color: $grey-1;
    border: 1px solid $grey-2;
    padding: 8px 16px;
    border-radius: 10px;

    .alt-food-link {
      cursor: pointer;
      color: $jungle-green;
      font-weight: bold;
    }
  }

  .add-to-diary-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .add-serving-measure-selector {
    .selection-dropdown {
      width: 243px;
    }
  }

  .add-to-diary-measure-selector {
    .selection-dropdown {
      width: 169px;
    }
  }

  .row-container-no-margin {
    margin: 0 !important;
  }
}

// Big yikes... This kooky CSS is overriding some big yuck in some over-shared
// widgets that it looks like we're all too scared to tweak... Derek wrote it
// in the _add-food-diary.scss file originally (I just moved it here to make it
// more specific, but please don't attribute the cringe to me...).
//
// "needed to hard code width and height lots moving around" -Derek
.add-serving-measure-selector,
.add-to-diary-measure-selector {
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    width: 100% !important;
    margin: 0 0 16px 0;
  }

  .selection-heading {
    width: 33.33% !important;
    font-weight: 600;
  }

  .amount {
    width: 64px;
    text-align: center;
    margin-right: 8px;
  }
}

.repeat-items-measure-selector {
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    width: 100% !important;
    margin: 0 0 16px 0;
  }

  .selection-heading {
    display: none !important;
  }

  .amount {
    width: 84px;
    text-align: center;
    margin-right: 8px;
  }

  .selection-dropdown {
    margin-left: 12px !important;
    width: 100%;
  }
}