.navbar {
  height: 56px;
}

.nav-item .indicator {
  background: $jungle-green;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 5%);
  color: $white;
  display: block;
  font-size: .675rem;
  height: 18px;
  padding: 1px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -6px;
  transition: top .1s ease-out;
  width: 18px;

  .dark-mode {
    background: $teal-2;
  }
}

.nav-icon {
  color: $orange;
  display: block;
  font-size: 1.3rem;
  line-height: 1.4;
  padding: 0.3rem 1.1rem;
}

.nav-icon .material-icons {
  font-size: 30px;
}

.nav-icon:hover {
  color: $orange;
  opacity: 0.7;
}

.navbar .navbar-nav .nav-link {
  color: $jungle-green;

  .dark-mode & {
    color: $teal-2;
  }
}

.navbar {
  background: $snow-white;
  border-bottom: 1px solid $grey-2;

  .dark-mode & {
    background: $dark-raisin;
    border-bottom: 1px solid $grey-7;
  }
}

.navbar-account {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.navbar-account:hover {
  text-decoration: none;
}

.navbar-dropdown-container {
  min-width: 400px;
  padding: 0 !important;
  border: 1px solid $grey-1 !important;
  border-radius: 10px !important;
  color: $snow-white !important;
  background: $raised-container-bg-dark !important;
}

.navbar-account::after {
  color: $jungle-green !important;

  .dark-mode & {
    color: $teal-2 !important;
  }
}

.navbar-dropdown-divider {
  margin: 12px 32px;
  border-top: 1px solid $raised-border-color-dark;
}

.navbar-checkmark {
  color: $teal-4 !important;
  font-size: 32px;
  margin: 8px 4px 0 0;

  .dark-mode & {
    color: $teal-2 !important;
  }
}

.navbar-wordmark {
  font-size: 18px;
}

.navbar-wordmark::before {
  color: $snow-white !important;
}

.switch-to {
  padding: 16px 32px;
}

.switch-to:hover {
  text-decoration: none;
  background: $raised-border-color-dark;
}

.upgrade {
  cursor: pointer !important;
  padding: 20px 35px;
}

.upgrade:hover {
  background: $raised-border-color-dark;
}