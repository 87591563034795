.protein-mercola {
  color: $sky-blue;
}

.summary-protein-mercola {
  .nutrient-target-bar-fill {
    background: $sky-blue;
  }
}

.carbs-mercola {
  color: $orange-7;
}

.summary-carbs-mercola {
  .nutrient-target-bar-fill {
    background: $orange-7;
  }
}

.fat-mercola {
  color: $jade-lake;
}

.summary-fat-mercola {
  .nutrient-target-bar-fill {
    background: $jade-lake;
  }
}
