.balance-gauge {
  width: 170px;
  margin: 0 0 16px 0;

  .gauge-label-container {
    width: 35px;
    height: 75px;
    float: left;
    vertical-align: bottom;
  }

  .gauge-label {
    font-size: 14px;
    font-weight: 600;
    min-width: 12px;
    margin: 55px 1px 0;
    padding: 3px 5px 4px;
    background: $snow-white;
    border-radius: 100%;
    border: 1px solid $grey-2;
    text-align: center;

    .dark-mode & {
      background: $grey-8;
      border: 1px solid $grey-9;
    }
  }

  .gauge-outer {
    height: 88px;
    background: url('https://cdn1.cronometer.com/pix/gauge-ring.png') no-repeat;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .gauge-outer:after {
    content: '';
    width: 88px;
    height: 15px;
    background: url('https://cdn1.cronometer.com/pix/gauge-mask-ring.png') no-repeat;
    display: block;
    position: absolute;
    bottom: 9px;
    left: 0;
  }

  .gauge-inner {
    width: 66px;
    height: 66px;
    background: $white url('https://cdn1.cronometer.com/pix/gauge-lines.png') no-repeat -11px -11px;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 11px;
    text-align: center;

    .dark-mode & {
      background: $raised-container-bg-dark url('https://cdn1.cronometer.com/pix/gauge-lines.png') no-repeat -11px -11px;
    }
  }

  .gauge-pointer {
    width: 40px;
    height: 40px;
    margin: auto;
    background: url('https://cdn1.cronometer.com/pix/gauge-pointers.png') no-repeat -1px 0;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -6px;
    right: 0;
  }

  .gauge-color {
    width: 150px;
    height: 150px;
    margin: -30px auto auto -75px;
    background: url('https://cdn1.cronometer.com/pix/gauge-colors.png') no-repeat -7px 50px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
  }

  .nb-red .gauge-color {
    background-position: -7px 50px;
  }

  .nb-potassium .gauge-color {
    background-position: -7px -83px;
  }

  .nb-pral .gauge-color {
    background-position: -7px -216px;
  }

  .nb-green .gauge-color {
    background-position: -7px -349px;
  }

  .nb-calcium .gauge-color {
    background-position: -7px -482px;
  }

  .nb-red .gauge-pointer {
    background-position: -1px 0;
  }

  .nb-potassium .gauge-pointer {
    background-position: -1px -90px;
  }

  .nb-pral .gauge-pointer {
    background-position: -1px -180px;
  }

  .nb-green .gauge-pointer {
    background-position: -1px -270px;
  }

  .nb-calcium .gauge-pointer {
    background-position: -1px -360px;
  }

  .gauge-value {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
  }

  .gauge-title {
    font-size: 14px;
    margin-top: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
}
