.energy-summary {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-3;

  .header-container {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .chart-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .title {
    font-weight: 600;
    text-align: center;
    margin: 12px 0 0 0;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.translucent {
  opacity: 0.1;
}

.target-summary.popup-menu {
  border: 1px solid $grey-2;
}

.target-summary {
  padding: 0;
  font-size: 14px;
  font-weight: normal;

  .popupContent {
    padding: 0 !important;
  }

  .table > :not(caption) > * > * {
    padding: 8px 16px;
  }

  .table {
    border: none !important;

    .table-header {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .table tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
  }

  .table tr:last-child td:last-child {
    border-bottom-right-radius: 0 !important;
  }

  .data-confidence {
    font-size: 12px;
    text-align: right;
    padding: 0 16px 8px 0;
  }
}
