.nutrient-target-bar-container {
  border-radius: 10px;
}

.target-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.ms-negative-3 {
  margin-left: -3px;
}

.target-bar {
  width: 100%;
  height: 12px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  background: $grey-1;
  display: inline-flex;
  vertical-align: middle;
  border: none;

  .dark-mode & {
    color: $dark-raisin;
    background: $grey-2;
  }
}

.target-text-container {
  display: flex;
  justify-content: space-between;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
}

.macro-summary-panel {
  .target-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;

    .title {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .target-bar-over {
    color: $dark-raisin !important;
  }

  /* exclamation icon when over */
  .target-bar-over::after {
    display: inline-flex;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath fill='%23D80C00' d='M512 960c-247.424 0-448-200.576-448-448s200.576-448 448-448c247.424 0 448 200.576 448 448v0c0 247.424-200.576 448-448 448v0zM512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512c-282.77 0-512 229.23-512 512v0c0 282.77 229.23 512 512 512v0z'%3E%3C/path%3E%3Cpath fill='%23D80C00' d='M455.111 725.333c-0.201-1.921-0.316-4.151-0.316-6.408 0-33.19 24.801-60.587 56.881-64.67l0.323-0.034c32.404 4.116 57.205 31.514 57.205 64.703 0 2.257-0.115 4.486-0.338 6.684l0.023-0.276c0.201 1.921 0.316 4.151 0.316 6.408 0 33.19-24.801 60.587-56.881 64.67l-0.323 0.034c-32.404-4.116-57.205-31.514-57.205-64.703 0-2.257 0.115-4.486 0.338-6.684l-0.023 0.276zM460.8 298.382c-0.285-2.383-0.447-5.143-0.447-7.941 0-21.272 9.392-40.35 24.256-53.313l0.084-0.072c7.356-6.084 16.886-9.774 27.278-9.774s19.922 3.691 27.35 9.832l-0.072-0.058c14.979 13.037 24.393 32.134 24.393 53.43 0 2.782-0.161 5.527-0.473 8.225l0.031-0.329-19.911 249.344c0.007 0.268 0.012 0.584 0.012 0.901 0 18.069-13.61 32.96-31.137 34.981l-0.163 0.015c-17.691-2.036-31.3-16.927-31.3-34.996 0-0.317 0.004-0.633 0.012-0.947l-0.001 0.046z'%3E%3C/path%3E%3C/svg%3E");
    // added to change colour to dark raisin cause I'm too lazy to swap to a BootstrapIcon
    filter: brightness(0) saturate(100%) invert(13%) sepia(12%) saturate(1483%) hue-rotate(191deg) brightness(100%) contrast(91%);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-left: 8px;
  }
}

.nutrient-target-bar-fill {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.summary-energy {
  .nutrient-target-bar-fill {
    background: $energy;
  }
}

.summary-protein {
  .nutrient-target-bar-fill {
    background: $protein;
  }
}

.summary-carbs {
  .nutrient-target-bar-fill {
    background: $carbs;
  }
}

.summary-fat {
  .nutrient-target-bar-fill {
    background: $fat;
  }
}

.w-624 {
  width: min(100%, 624px);
}

.bullet {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  border-radius: 50%;
}

.macro-targets {
  .calculator-container {
    border: 1px solid;
    border-radius: 10px;
    padding: 32px;
  }

  .keto-container {
    border: 1px solid;
    border-radius: 20px;
    padding: 32px;
    width: min(100%, 748px);
  }

  .keto-pill-container {
    width: 100%;
    border-radius: 20px;
    text-align: center;
    padding: 12px 50px;
  }

  .inner-container-macros {
    width: min(100%, 560px);
  }

  .energy-target-link {
    color: inherit;
    text-decoration: underline;
  }

  .mw-430 {
    width: min(100%, 430px);
  }
}
