.water-tracking-target-bar {

  .nutrient-target-bar-fill {
    height: 100%;
    background: $grey-5;
    transition: all 0.2s ease-in-out;
  }

  .nutrient-target-bar-overflow {
    background: $orange-9;
  }

  .nutrient-target-bar-optimal {
    background: $green-4;
  }
}

.water-slider-container {
  background-color: $snow-white;
  border: 1px solid var(--grey-1);
  border-radius: 10px;
  margin-left: 16px;
  margin-right: 16px;
}

.water-tracking {
  .collapsible-section {
    i {
      transform-origin: 50% 50%;
    }
  }
}


.water-slider-inner {
  padding: 8px 10px 10px;
}

.water-goal-met-icon {
  color: #1FCAD7;
}

.water-glass-button {
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;
  text-align: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 2px;
  left: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.water-glass-button-background {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.water-glass-button-background:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.water-glass-overlay-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.water-glass-bulk-add-button {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.water-glass-bulk-add-button:hover {
  opacity: 1;
}

.water-dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;

  .error {
    margin-top: .25rem;
    width: 350px;
    text-wrap: pretty;
  }
}

.water-settings-link {
  .error {
    width: 150%;
    text-wrap: pretty;
  }
}