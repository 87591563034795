
.dynamic-add-food-container,
.dynamic-add-exercise-container,
.dynamic-add-biometric-container {
  height: calc(100vh - 96px);
  max-height: 1000px;
  min-height: 450px;
  display: flex;
  flex-direction: column;

  .static-panel {
    flex-shrink: 0;
  }

  .top-level-scroll-container {
    flex-shrink: 1;
    overflow: hidden;
    display: flex;
    width: 100%;
  }
}

// this ugliness is to handle the GWT tab panels in these dialogs
// they create these awful nested table structures that are difficult to style
.dynamic-add-exercise-container,
.dynamic-add-biometric-container {
  width: 880px;
  max-width: 100%;
  margin: auto;

  .top-level-scroll-container {
    & > tbody {
      display: flex;
      flex-direction: column;
      width: 100%;

      & > tr:nth-of-type(2) {
        border-radius: 10px;
        border: 1px $grey-2 solid;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;

        & > * {
          width: 100%;
        }
      }
    }
  }
}