//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&family=Work+Sans:wght@600;700&display=swap');

$font-family-base: 'Manrope', sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-negative-margins: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$border-radius-sm: 0.25rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 0.625rem;
$border-radius-xl: 1.25rem;

$border-thickness-thicc: 2px;
$border-thickness-thiccc: 3px;
$border-thickness-thicccc: 4px;
$border-thickness-thiccccc: 5px;

// add our own spacing divisions
$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * 0.25,
        2: $spacer * 0.5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 2,
        6: $spacer * 3,
        8: $spacer * 4,
        10: $spacer * 5,
        12: $spacer * 6
);

//---------------------------------------------
// 3. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
        xxxl: 1600px
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1560px
);

//---------------------------------------------
// 4. Cronometer default colours
//---------------------------------------------

$crono-orange: #FF6733;
$dark-raisin: #262A3B;
$white: #FFFFFF;
$snow-white: #FAFBFF;
$floral-white: #FFFCF6;
$honey-gold: #FFAD00;
$sky-blue: #1CCAD7;
$jungle-green: #005C5C;
$jade-lake: #44D07B;
$lupine-purple: #AE61C2;
$fire-weed: #CC45A9;
$modal-dark-mode-bg: #404252;
$modal-dark-mode-border: #5B5D6B;

//---------------------------------------------
// 5. Cronometer tints and shades
//---------------------------------------------

// Greyscale
$grey-1: #F0F2FA;
$grey-2: #E6E8F0;
$grey-3: #C3C6D3;
$grey-4: #AFB4C4;
$grey-5: #9DA0AD;
$grey-6: #777986;
$grey-7: #5B5D6B;
$grey-8: #404252;
$grey-9: #101223;

// Orange
$orange-1: #FFDACE;
$orange-2: #FFC4B1;
$orange-3: #FFAD93;
$orange-4: #FF9775;
$orange-5: #FF7E56;
$orange-6: #FB521E;
$orange-7: #EA3B04;
$orange-8: #E32C00;
$orange-9: #D80C00;
$orange-10: #D80C00;

// Yellow
$yellow-1: #FFEAC6;
$yellow-2: #FFDFA3;
$yellow-3: #FFD27F;
$yellow-4: #FFC659;
$yellow-5: #FFBA24;
$yellow-6: #FF9A00;
$yellow-7: #F38D00;
$yellow-8: #E58200;
$yellow-9: #D67100;
$yellow-10: #C96400;

// Blue
$blue-1: #CEF1F5;
$blue-2: #B0E9EE;
$blue-3: #91E1E9;
$blue-4: #6FD8E2;
$blue-5: #46D0DC;
$blue-6: #00BFC6;
$blue-7: #00B2B4;
$blue-8: #00A3A1;
$blue-9: #008F8A;
$blue-10: #007770;

// Teal
$teal-0: #DCEAE6;
$teal-1: #B9D7D6;
$teal-2: #8FBEBE;
$teal-3: #64A6A6;
$teal-4: #328E8E;
$teal-5: #007574;
$teal-6: #005350;
$teal-7: #004541;
$teal-8: #003631;
$teal-9: #002420;
$teal-10: #001713;
// Green
$green-1: #D5F3DF;
$green-2: #BBECCA;
$green-3: #A1E6B7;
$green-4: #85DEA3;
$green-5: #67D68F;
$green-6: #3CC26E;
$green-7: #14B255;
$green-8: #0FA247;
$green-9: #038B35;
$green-10: #006F24;

// Purple
$purple-1: #F6D9FB;
$purple-2: #E8C1F0;
$purple-3: #DAA9E4;
$purple-4: #CC91D9;
$purple-5: #BD79CD;
$purple-6: #9F4EB4;
$purple-7: #8A399F;
$purple-8: #77268D;
$purple-9: #68177E;
$purple-10: #55066A;

// Pink
$pink-1: #FFD7F1;
$pink-2: #F6BBE2;
$pink-3: #EDA0D4;
$pink-4: #E384C6;
$pink-5: #D866B7;
$pink-6: #BF359B;
$pink-7: #A83189;
$pink-8: #912B77;
$pink-9: #711B5B;
$pink-10: #5F114B;

//---------------------------------------------
// 6. Cronometer Non-Default Colours
//---------------------------------------------

$crono-error: #CD0A01;
$shadow-default: rgba($grey-6, 0.1) 0 3px 20px;

//---------------------------------------------
// 7. Macro colors
// @see https://xd.adobe.com/view/e3bbf37d-9ba7-4866-afe8-8a0b08abd3b5-b6e8/screen/39a3792d-11de-4491-8acb-a2a27c985adb/specs/
//---------------------------------------------
$energy: $grey-5;
$carbs: $sky-blue;
$protein: $jade-lake;
$fat: $orange-7;
$exercise: $yellow-7;
$tef: $yellow-9;
$activity: $teal-4;
$bmr: $lupine-purple;
$fasting: $blue-8;
$alcohol: $honey-gold;
$nutrition-scores: $pink-5;
$hundred-percent: $green-4;
$target-over: $orange-9;
$nutrient-targets-fill: $grey-4;

//--------------------
// 8. Raised container
//--------------------
$raised-container-bg-light: #ffffff;
$raised-border-color-light: #F0F2F9;
$raised-container-bg-dark: #272A3A;
$raised-border-color-dark: #3B3F4D;
$shadow: #7779861A;

//---------------------------------------------
// 9. Bootstrap Colors
//---------------------------------------------

$body-color: $dark-raisin; // The default text color
$primary: $crono-orange;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: $dark-raisin;

//---------------------------------------------
// 10. Font Sizes
//---------------------------------------------
$font-size-base: 1rem;

$font-sizes: (
        1: $font-size-base * 2.5,
        2: $font-size-base * 2,
        3: $font-size-base * 1.75,
        4: $font-size-base * 1.5,
        5: $font-size-base * 1.25,
        6: $font-size-base,
        7: $font-size-base * .87,
        8: $font-size-base * .75,
        9: $font-size-base * .50,
        inherit: inherit
);
