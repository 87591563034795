.dashboard-container {
  width: 100%;
}

.dashboard-card {
  @extend .container-raised;
  height: fit-content;
  width: 406px;
  margin: 12px;
  position: relative;

  .axis-unit-container {
    margin-right: 0 !important;
  }

  .chevron {
    position: absolute;
    right: 20px;
    transition: 0.2s ease-in-out;
  }
}

dashboard-chevron-right {
  position: absolute;
  right: 34px;
}

.braze-card {
  @extend .dashboard-card;
  padding: 0;
  background-clip: border-box;
}

.braze-image-container {
  max-width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
}