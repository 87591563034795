.pro-directory {
  .header {
    .header-background {
      background: $dark-raisin;
    }

    .nav-link {
      color: $snow-white;
      margin: 0 16px 0 0;
    }

    .nav-link:hover {
      opacity: 0.7;
    }

    .dropdown-toggle:after {
      color: $snow-white;
    }
  }

  .spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 32px 0;
  }

  .banner-container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #fff;
    margin: 32px 0;
    box-shadow: 0 0 16px 0 #e2e2e2;

    .banner-logo-container {
      display: flex;
      align-items: center;
    }

    .banner-learn-more {
      display: flex;
      justify-content: right;
    }
  }

  .pro-directory-pager {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .pro-directory-back-btn {
    display: flex;
    width: 270px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .info-container {
    margin: 32px 0;
    border-radius: 10px;
    border: 1px solid $grey-2;

    .info-row {
      --bs-gutter-x: 1.6rem;
    }

    .info-panel {
      padding: 0;
    }

    .info-header {
      font-weight: 600;
      background: $grey-2;
      padding: 8px 16px;
    }

    .info-header-left-radius {
      border-top-left-radius: 10px;
    }

    .info-header-right-radius {
      border-top-right-radius: 10px;
    }

    .info-content {
      padding: 32px 16px;
    }
  }

  .info-container-left-border {
    border-left: 1px solid $grey-2;
  }


}
