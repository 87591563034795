.plans-panel {
  .plans-price-txt {
    font-size: 36px;
    font-weight: bold;
    color: $dark-raisin;
    font-family: Work Sans;
  }

  .plans-price-txt-discount {
    font-size: 36px;
    font-weight: bold;
    color: $grey-6;
    font-font: Work Sans;
    text-decoration: line-through;
    z-index: 1000;
  }

  .discount-note-text {
    z-index: 10;
  }

  .gold-features-txt {
    font-size: 28px;
    font-weight: bold;
    color: $dark-raisin;
  }

  .features-price-txt {
    font-size: 22px;
    font-weight: bold;
    color: $dark-raisin;
    font-family: Work Sans;
  }

  .checks {
    align-self: center;
    display: grid;
    grid-template-columns: 120px auto;
  }

  .check-margin {
    margin-right: 10px;
    margin-left: 86px;
  }

  .plans-currency-txt {
    color: $dark-raisin;
  }

  .features-background {
    background-color: $snow-white;
  }

  .features-single-row {
    padding-left: 22px;
    padding-right: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .features-double-row {
    padding-left: 22px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .text-align-end {
    text-align: end;
  }

  .sub-incomplete {
    background-color: $orange-1;
  }

  .faq-header {
    text-align: left;
    color: $dark-raisin;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
  }

  .faq-header:hover {
    opacity: 0.7;
  }

  .faq-arrow {
    transform: rotate(180deg);
    transition: all 200ms ease-in-out;
    width: 25px;
    height: 25px;
    color: $jungle-green;
  }

  .faq-arrow:hover {
    opacity: 0.7;
  }

  .plan-discount-container {
    background-color: $blue-1;
    border-color: $blue-2;;
    padding: 10px;
  }

  .trial-panel {
    background-color: $yellow-1;
  }

  .trial-description {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
  }

  .me-auto-fail {
    position: relative;
    right: -200px;
  }

  .me-auto-fail-dropdown {
    position: relative;
    right: -336px;
  }

  .basic-gold-titles {
    display: grid;
    grid-template-columns: 116px 77px;
  }

  .plan-logo-text {
    font-size: 32px;
    font-weight: 800;
    vertical-align: middle;
  }

  .plan-logo-text-gold {
    font-size: 32px;
    font-weight: 800;
    vertical-align: middle;
    color: $honey-gold;
  }

  .mt-50 {
    margin-top: 50px;
  }
}

.stripe-dialog {
  .credit-card {
    padding-bottom: 5px;
    border-bottom: 1px solid $grey-2;
  }

  .credit-card-element {
    padding: 20px;
    background: $snow-white;
    border: 1px solid $grey-2;
    border-radius: 3px;
  }

  .card-errors {
    margin: 20px;
    color: $orange-10;
  }

  .required {
    font-style: italic;
    color: $grey-6;
    margin-top: 15px;
  }

  .price-txt {
    font-weight: 800;
    font-size: 46px;
    color: $dark-raisin;
    font-family: Work Sans;
  }

  .currency-month {
    font-size: 18px;
    text-align: center;
    letter-spacing: 1.35px;
    color: $dark-raisin;
  }

  .error-icon {
    color: $orange-10;
    width: 20px;
    height: auto;
  }

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .password-container {
    position: relative;
    display: block;
  }

  .show-password {
    position: absolute;
    top: 12px;
    right: 5px;
    opacity: 0.5;
    cursor: pointer;
  }

  .show-password:hover {
    opacity: 1.0;
  }

  .input-stripe-dialog {
    font-size: 16px;
    font-weight: 300;
    color: #404040;
    background: $snow-white;
    border: none;
    border-bottom: 1px solid $grey-3;
    padding: 5px 0;
  }

  .job-titles-container .gwt-ListBox {
    font-size: 16px;
    font-weight: 300;
    color: #404040;
    background: $snow-white;
    border: 1px solid $grey-3;
    border-radius: 3px;
    padding: 5px 0;
  }

  .type-other-txt {
    margin-left: 40px;
  }

  .practice-divider {
    border: none;
    border-top: 1px solid $grey-3;
  }
}
