.mini-chart {
  .highcharts-container {
    margin: auto;
  }
}

.trends-chart {
  .chart-title {
    font-size: 18px;
    font-weight: bold;
  }

  &.full-chart {
    .axis-unit-container {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      margin-right: 65px;
    }

    .options-panel {
      display: flex;
      flex-direction: row;
      z-index: 2;
      width: 100%;
      align-items: baseline;
    }

    .option-wrapper-container {
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 15px;
      margin-left: auto;
    }

    .option-container {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    .option-container > * {
      margin-right: 16px;
    }

    .unit-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .chevron {
      transition: 0.2s ease-in-out;
      align-self: center;
      margin-bottom: 15px;
      margin-left: 20px;
      margin-right: 14px;
      color: var(--jungle-green);
    }
  }

  &.mini-chart {
    .options-panel {
      display: flex;
      flex-direction: row;
    }
  }

  .unit-labels {
    font-weight: 600;
    margin-right: 8px;
  }

  .setting-label {
    flex: 1;
    max-width: 350px;
    font-weight: 600;
    padding-right: 8px;
  }

  .overflow-actions {
    color: #005C5C;
    cursor: pointer;
    padding: 2px;
  }

}

.chart-source-label-offset {
  bottom: 2.5rem;
}

.chart-source-desc-outer-align-right {
  right: 0;
}


.chart-stat-section {
  background: $snow-white;
  color: $grey-6;
  border: 1px solid $grey-2;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.35;
}

.chart-stat-container {
  display: inline-block;
  padding: 8px 16px 10px 16px;

  .stat-value {
    color: $dark-raisin;
    font-size: 20px;
    font-family: "Work Sans", sans-serif;
    line-height: 1.2;
  }
}

.chart-stat-popup {
  background: $white;
  border: 1px solid $grey-1;
  border-radius: 10px;
  padding: 24px;

  .title {
    font-size: 20px;
    font-weight: 600;
    font-family: "Work Sans", sans-serif;
  }

  .details {
    max-width: 340px;
    font-size: 14px;
    font-weight: 400;
  }
}

.ranges-container {
    display: inline-flex;
    gap: 16px;
    padding: 16px;

    .range-section {
      .color-indicator {
        align-self: stretch;
        width: 4px;
        border-radius: 2px;
      }

      .separator-border {
        border-left: 1px solid $grey-3;
      }
    }
  }
