.scroll-custom {
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $grey-3;

    &:hover {
      background-color: $grey-4;
    }
  }

  &::-webkit-scrollbar-button:start:increment, &::-webkit-scrollbar-button:end:increment {
    height: 4px;
    display: block;
    background: transparent;
  }
}