.big-pill {
  cursor: pointer;
  background: #E65F29;
  color: white;
  border-radius: 10px;
  padding: 12px 12px;
  font-size: 14px;
  margin: 8px 3px;
  min-width: 60px;
  text-align: center;
  display: inline-block;
}

.big-pill.disabled {
  background: #5c5c5c;
}
