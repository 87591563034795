.daterange-picker-date-td {
    border: 1px solid #e6e8f0;
    background-color: white;
}

.date-range-picker tbody tr td div.datePickerDay {
    padding: map-get($spacers, 3) map-get($spacers, 2);
}

.date-range-picker tbody tr td.datePickerWeekdayLabel {
    padding: map-get($spacers, 3);
}

.date-range-picker table.datePickerMonthSelector {
    border-collapse: initial;
}

.date-picker-open-ended-label {
    margin-right: 110px !important;
}

.date-picker-click-blocker {
    position: absolute;
    width: 300px;
    z-index: 9999999;
    opacity: .5;
    height: 560px;
    display: block;
}

.date-range-picker .datePickerMonth {
    width: 30%;
}

// align arrows closer to the month label
.date-range-picker .datePickerPreviousButton {
    display: flex;
    justify-content: flex-end;
}
