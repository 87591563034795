.message-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: $yellow-1;
  border: 1px solid $yellow-3;
  border-radius: 10px;
  padding: 1rem 1rem;
  font-size: 18px;
  color: $dark-raisin;
  box-shadow: rgba(119, 121, 134, 0.15) 0 3px 20px;
}
