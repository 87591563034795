.add-food-diary {
  transition: all 100ms ease-in-out;

  .food-search-spinner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 60px 0 0 0;
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  .search-container-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0 0 0;

    .food-search-btn {
      margin: 0 0 0 16px;

      .dark-mode & {
        color: $teal-2;
      }
    }

    .food-search-btn:hover {
      .dark-mode & {
        background: $grey-6;
      }
    }
  }

  .search-container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background: $snow-white;
    border: 1px solid $grey-6;
    border-radius: 4px;

    .dark-mode & {
      background: $grey-2;
    }

    .search-icon {
      color: $jungle-green;
      font-size: 24px;
      padding: 0 16px;
    }

    .filter-icon {
      color: $crono-orange;
      font-size: 24px;
      padding: 0 16px;
      cursor: pointer;
      border-left: 1px solid $grey-6;
      line-height: 40px;
    }

    .filter-icon:hover {
      opacity: 0.7;
    }

    .search-field {
      border: none;
      background: $snow-white;
      width: 100%;

      .dark-mode & {
        background: $grey-2;
      }
    }

    .search-field:focus-visible,
    .search-field:active,
    .search-field:focus {
      border: none;
      outline: none;
    }

    .icon-x-circle-fill {
      font-size: 18px;
      color: $grey-6;
      cursor: pointer;
      margin: 0 16px 0 0;
    }

    .icon-x-circle-fill:hover {
      opacity: 0.8;
    }
  }

  .search-options-container-wrapper {
    max-height: 0;
    display: flex;
    flex-direction: column;
    transition: all 100ms ease-in-out;

    .search-options-container {
      background: $grey-1;
      border-radius: 10px;
      padding: 16px 32px;
      margin: 8px 0;

      .dark-mode & {
        background: $grey-7;
      }

      .search-options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .search-option-category {
          font-weight: 600;
          margin: 0 0 8px 0;
        }
      }
    }
  }

  .search-tab-container {
    display: flex;
    padding: 16px 0 8px 0;

    .search-tab {
      cursor: pointer;
      color: $teal-3;
      font-weight: 700;
      padding: 4px 16px 0;
    }

    .food-search-selected-tab {
      color: $jungle-green;
      border-bottom: 2px solid $crono-orange;
      transition: all 100ms ease-in;

      .dark-mode & {
        color: $teal-2;
      }
    }
  }

  .results-container {
    border: 1px solid $grey-2;
    border-radius: 10px;

    .dark-mode & {
      border: 1px solid $grey-6;
    }

    table {
      border: none;
      margin-bottom: 0;
    }

    table tr:not(:first-child):hover {
      cursor: pointer;
    }

    .table-header td:nth-child(2) {
      text-align: center;
    }
  }

  /* scrollbar */
  .results-container::-webkit-scrollbar {
    width: 8px;
  }

  /* track */
  .results-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px $raised-container-bg-dark;
    border-radius: 10px;
  }

  /* handle */
  .results-container::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }

  /* handle on hover */
  .results-container::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }

  // summary total popup
  .energy-summary-totals {
    bottom: 100px;
  }

  .nutrient-info-container {

    .nutrient-name {
      font-weight: 600;
      font-size: 16px;
      margin: 0 8px 0 0;
    }

    .nutrient-category {
      margin: 0 8px 0 0;
    }
  }

}

// food result diary icons
// FoodSearchPanel::setResults()
.source {
  padding: 0 0 0 24px;
}

.source-usda {
  background-image: url('https://cdn1.cronometer.com/media/flags/us.png');
  background-repeat: no-repeat;
  background-position: left center;
}

.source-upc {
  background-image: url('https://cdn1.cronometer.com/media/upc.png');
  background-repeat: no-repeat;
  background-position: left center;
}

.source-cnf {
  background-image: url('https://cdn1.cronometer.com/media/flags/ca.png');
  background-repeat: no-repeat;
  background-position: left center;
}

.source-lab {
  background-image: url('https://cdn1.cronometer.com/brand/svg/lab-icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
}

.source-label-custom {
  color: $blue-10;
}

.source-custom {
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-image: url("/img/custom.svg");
  background-position: left center;
}

.source-custom-meal {
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-image: url("https://cdn1.cronometer.com/2023/svg/meals-icon.svg");
  background-position: left center;
}

.source-barcode {
  background-image: url('https://cdn1.cronometer.com/brand/svg/barcode-icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
}
