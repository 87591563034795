
.card {
  @extend .container-raised;
  padding: 16px;
  width: 168px;
  height: 140px;

  &:hover {
    box-shadow: 0 3px 8px $grey-3;
    cursor: pointer;
  }

  // Set colour states for activity/biometric cards
  &.card-crono-orange {
    &.selected {
      border: 1px solid $crono-orange;
    }
  }

  &.card-blue-6 {
    &.selected {
      border: 1px solid $blue-6;
    }
  }

}

.card-icon {
  width: 60px;
  height: auto;

  filter: brightness(0) saturate(100%) invert(77%) sepia(13%) saturate(222%) hue-rotate(191deg) brightness(80%) contrast(91%);
}

.card-name {
  text-align: center;
}


// Keeping this here as an example of coloring our mono-chrome icons because it might be used again in the future
/*
&.card-crono-orange {
  &:hover, &:focus {
    color: $crono-orange;

    // Icon colouring. See https://codepen.io/sosuke/pen/Pjoqqp
    // Note required brightness & saturation b/c our original icons are not black
    .card-icon {
      filter: brightness(0) saturate(100%) invert(78%) sepia(72%) saturate(5115%) hue-rotate(333deg) brightness(102%) contrast(101%);
      -webkit-filter: brightness(0) saturate(100%) invert(78%) sepia(72%) saturate(5115%) hue-rotate(333deg) brightness(102%) contrast(101%);
    }

    &:focus {
      border: 1px solid $crono-orange;
    }

  }
}*/
