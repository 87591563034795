.pending-status-pill {
    padding: ($spacer * 0.5625) ($spacer);
    border: none;
    background-color: $yellow-1;
    color: $yellow-10;
}

.account-created-status-pill {
    padding: ($spacer * 0.5625) ($spacer);
    border: none;
    background-color: $green-1;
    color: $green-10;
}

.gold-sub-status-pill {
    padding: ($spacer * 0.5625) ($spacer);
    border: none;
    background-color: $orange-1;
    color: $orange-7;
}