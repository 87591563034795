.sidebar {
  background: $raised-container-bg-dark;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.sidebar-link-container {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100% !important;
  top: 60px;
  z-index: 8888;
}

.sidebar-wrapper {
  overflow-y: auto;
  max-height: 85vh;
  width: 295px;
}

/* scrollbar */
.sidebar-wrapper::-webkit-scrollbar {
  width: 4px;
}

/* track */
.sidebar-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px $raised-container-bg-dark;
  border-radius: 10px;
}

/* handle */
.sidebar-wrapper::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}

/* handle on hover */
.sidebar-wrapper::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 10px;
}

.sidebar-content {
  width: 300px;
  transition: all 100ms ease-in-out;

  a:hover {
    text-decoration: none;
  }
}

.btn-sidebar {
  width: 100%;
  height: 35px;
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: $light;
  background-color: transparent;
  border: 0;
}

.btn-toggle:hover i:before,
.btn-toggle.active i:before,
.btn-sidebar:hover i:before,
.btn-sidebar.active i:before,
.btn-sidebar.active .nav-title {
  color: $primary !important;
}

.btn-sidebar:hover {
  color: $primary !important;
  background: $raised-border-color-dark;
}

.sidebar-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(255, 255, 255, 5%);
}

.sidebar-toggle {
  padding: 4px 4px 0 4px;
  margin: 8px 8px 0 0;
}

.sidebar-toggle span {
  color: $snow-white;
  font-size: 30px;
}

.sidebar-toggle span:hover {
  color: $grey-3;
}

.btn-toggle {
  width: 100%;
  height: 35px;
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: $white;
  background-color: transparent;
  border: 0;
}

.btn-toggle.active::after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb%28255,103,51%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle:hover {
  color: $primary !important;
  background: $raised-border-color-dark;
}

.btn-toggle::after {
  margin-left: auto;
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,1%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 150ms ease-in-out;
  transform-origin: .5em 50%;
  transform: rotate(90deg);
}

.btn-toggle:hover::after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb%28255,103,51%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: $white;
}

.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(-90deg);
}

.nav-item .active {
  color: $primary !important;
  background-color: transparent !important;
}

.sidebar-nav-container {
  :first-child {
    margin-top: 6px;
  }
}

.sidebar-nav-container a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  margin-left: 1.25rem;
  text-decoration: none;
}

.sidebar-nav-container a:hover {
  color: $primary !important;
}

.sidebar-nav-item .btn:focus {
  box-shadow: none;
}

.sidebar-nav-item .btn:focus-visible {
  outline-style: solid;
  outline-color: white;
  outline-width: 2px;
}

.sidebar-icon {
  width: 24px;
  height: 24px;
}

.sidebar .nav-title,
.sidebar .nav-title-with-children {
  font-size: 18px;
  font-style: normal;
  margin-left: 6px;
}

.client-name {
  max-width: 188px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logo-container {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 7px 0 5px 20px;
  background: $raised-container-bg-dark;
  border-bottom: 1px solid $raised-border-color-dark;
  border-top-right-radius: 20px;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.logomark {
  margin-right: 2px;
}

.sidebar-nav-container .nav-link {
  margin-left: 30px;
}

.sidebar-links {
  padding: 10px 0px;
}

.sidebar-nav-item {
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.lightswitch-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer !important;
  margin: 0 0 0 4px;
}

.lightswitch {
  padding-left: 44px;
  align-items: center;
}

.lightswitch-label {
  margin-right: 5px;
}

// social icons
.sidebar-social-icons {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 4px;
  margin: 16px 0 0 0;
}

.sidebar-unread {
  display: inline;
}

.app-links-container {
  padding: 0 16px;
}

// Collapsed sidebar state
body[data-sidebar-compact=true] .sidebar .btn-toggle span,
body[data-sidebar-compact=true] .sidebar .lightswitch,
body[data-sidebar-compact=true] .btn-sidebar span,
body[data-sidebar-compact=true] .sidebar-unread {
  display: none;
}

body[data-sidebar-compact=true] .btn-sidebar span.material-icons-outlined {
  display: inline-flex !important;
}

body[data-sidebar-compact=true] .sidebar .btn-toggle::after {
  content: "";
}

body[data-sidebar-compact=true] .sidebar-toggle {
  margin: 16px 11px 0 5px;
}

body[data-sidebar-compact=true] .sidebar .sidebar-nav-item {
  padding: 5px 15px;
}

body[data-sidebar-compact=true] .sidebar-links {
  padding: 0;
}

body[data-sidebar-compact=true] .sidebar hr {
  width: 70px;
}

body[data-sidebar-compact=true] .sidebar-nav-container {
  display: none;
}

body[data-sidebar-compact=true] .logo-container {
  padding: 0 9px 6px 5px;
  border-top-right-radius: 20px;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover .lightswitch {
  display: inline-flex !important;
  margin-left: 30px;
}

body[data-sidebar-compact=true] .lightswitch-container:hover {
  margin: 0;
}

body[data-sidebar-compact=true] .lightswitch-container:hover .lightswitch-label {
  margin-left: 4px;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover .collapse,
body[data-sidebar-compact=true] .sidebar-nav-item:hover .sidebar-nav-container,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within .collapse,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within .sidebar-nav-container {
  display: block !important;
  transition: none !important;
  --webkit-transition: none !important;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover .sidebar-nav-container,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within .sidebar-nav-container {
  display: block !important;
  left: 70px;
  position: absolute;
  background: $raised-border-color-dark;
  width: 230px;
  padding-bottom: 14px !important;
  border-bottom-right-radius: 5px;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover .sidebar-nav-container .nav-link,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within .sidebar-nav-container .nav-link {
  margin: 0 0 0 20px;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within {
  background: $raised-border-color-dark;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 300px;
  color: white;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover a.active i,
body[data-sidebar-compact=true] .sidebar-nav-item:hover a.active .nav-title-with-children {
  color: $primary;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover i {
  color: white;
  display: inline-flex;
  align-items: center;
}

body[data-sidebar-compact=true] .sidebar-nav-item:hover .nav-title-with-children,
body[data-sidebar-compact=true] .sidebar-nav-item:hover .nav-title,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within .nav-title-with-children,
body[data-sidebar-compact=true] .sidebar-nav-item:focus-within .nav-title {
  display: block;
  margin-left: 45px;
  color: white;
}

/* Don't show the "new-thing-sparkles" when the sidebar is compact */
body[data-sidebar-compact=true] .sidebar {
  .new-thing-sparkles {
    display: none;
  }
}

/* Because the "new-thing-sparkles" is absolutely positioned, it will not be hidden by the
    animation applied by the "collapsing" class, so just hide it during the slide */
.sidebar {
  .collapsing {
    .new-thing-sparkles {
      display: none;
    }
  }
}