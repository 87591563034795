.page-title {
  font-family: "Work Sans", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
  letter-spacing: 0;
}

.page-description {
  font-size: 16px;
  font-weight: normal;
}

.container-title {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 33px;
  letter-spacing: 1.1px;
}

.container-description {
  font-size: 16px;
  font-weight: 600;
}

.container-header {
  font-size: 18px;
  font-weight: 800;
}

.banner-title {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.collapsible-section-title {
  font-size: 16px;
  font-weight: bold;
  color: $jungle-green;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.text-muted {
  opacity: 0.9;
}

.text-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-a-bit-smaller {
  font-size: 14px;
}