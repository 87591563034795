.time-input-container {
  white-space: nowrap;

  .delete {
    display: inline-flex;
  }

  .time-label {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .popup-time-picker-box {
    padding: 2px 8px;
    border: 1px solid $grey-4;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    .dark-mode & {
      color: $snow-white;
      background-color: $modal-dark-mode-bg;
      border: 1px solid $modal-dark-mode-border;
    }
  }

  .popup-time-hour {
    margin-right: 5px;
  }

  .popup-time-minute {
    margin-left: 5px;
  }

  .popup-time-selector {
    margin-left: 10px;
  }

  .popup-time-buttons {
    margin: 0 85px 20px;
  }

  .popup-time-ok-button {
    margin-left: 20px;
  }
}

.time-label-container {
  display: inline-flex;
}