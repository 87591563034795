.daily-target-editor-hamburger {
  border-radius: 10px;
  width: 18px;
  height: 3px;
  background-color: $jungle-green;
  margin: 2px 0;

  .dark-mode & {
    background-color: $teal-2;
  }
}

.default-settings {
  a {
    text-decoration: underline;
    color: #005C5C;
    font-weight: 600;
  }

  a:hover {
    text-decoration: none;
  }
}

.daily-target-editor-title {
  color: $jungle-green;
  font-weight: bold;
  margin-left: 20px;
  letter-spacing: 1.1px;

  .dark-mode & {
    color: $teal-2;
  }
}
