.button-group-container {
  background-color: var(--snow-white);
  border: 1px solid var(--grey-3);
  border-radius: 3rem;
  padding: 3px;
  align-items: center;
}

.button-group-child-layout-override {
  padding: 9px 40px;
  box-shadow: none;
  text-transform: none;
  border-radius: 3rem;
}

.button-group-divider {
  width: 1px;
  height: 70%;
  background-color: var(--grey-3);
}