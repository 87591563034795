.nutrient-targets-container {
  display: flex;
  flex-direction: column;

  .header-container {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }
}

.circle-target-inner-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
  background: $white;

  .dark-mode & {
    background: $raised-container-bg-dark;
  }
}

.circle-target-inner-circle:hover {
  background: $grey-1;

  .dark-mode & {
    background: $grey-8;
  }
}

.targets-table > tbody > tr:nth-of-type(odd) {
  .dark-mode & {
    background-color: $grey-3;
  }
}

.targets-table {
  font-size: 14px;
  margin-top: 16px;

  .dark-mode & {
    color: $snow-white;
    background: $grey-7;
    border: 1px solid $grey-8;
  }

  td {
    vertical-align: middle;
  }

  .category,
  .category td {
    font-size: 14px;

    .dark-mode & {
      color: $grey-3;
      background-color: $grey-8 !important;
    }
  }

  .targets-table-nutrient-name {
    padding: 0 0 0 0.65rem;
  }

  .nutrient-name {
    font-weight: 400;
    min-width: 135px;
    cursor: pointer;
  }

  .target-text {
    font-size: 14px;
    font-weight: 600;
  }

  .targets-table-number {
    padding: 0 4px 0 0 !important;
    width: 80px;
  }

  .targets-table-unit {
    padding: 0 !important;
    width: 50px;
  }

  .targets-table-target-bar {
    padding: 0.4rem 0;
  }

  .target-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .dark-mode & {

    }

    .target-text-container {
      display: flex;
      justify-content: center;
      width: 85px;
      margin-left: 8px;
      margin-right: 0.65rem;
      font-weight: normal;
    }

    .nutrient-target-bar-container {
      min-width: 4px;
    }
  }

  .nutrient-target-bar-fill {
    height: 100%;
    background: $grey-5;
  }

  .nutrient-target-bar-overflow {
    background: $orange-9;
  }

  .nutrient-target-bar-optimal {
    background: $green-4;
  }

  .nutrient-target-bar-text {
    .dark-mode & {
      color: $dark-raisin;
    }
  }
}

.target-text.no-target {
  color: $grey-4;
}

.remaining-protein {
  .nutrient-target-bar-container {
    background: $green-2;
  }
}

.remaining-carbs {
  .nutrient-target-bar-container {
    background: $blue-2;
  }
}

.remaining-fat {
  .nutrient-target-bar-container {
    background: $orange-2;
  }
}

.popupContent {
  .energy-summary-totals.macro-hover-menu {
    left: inherit;
    bottom: inherit;
    position: inherit;
  }
}