$custom-radius: 10px;

.crono-table {
  background-color: $white;
  border-radius: $custom-radius;
  border: 1px $grey-2 solid;
  border-spacing: 0;
  border-collapse: separate;

  .dark-mode & {
    background-color: $grey-3;
  }

  & td {
    border: none;
    padding: 0.4rem 0.65rem;
  }

  & tr:first-child td:first-child {
    border-top-left-radius: $custom-radius - 2;
    border-bottom-left-radius: 0;
  }

  & tr:first-child td:last-child {
    border-top-right-radius: $custom-radius - 2;
    border-bottom-right-radius: 0;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: $custom-radius - 2;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: $custom-radius - 2;
  }

  & :not(:first-child) {
    border-top: none;
  }

  & tbody {
    tr:nth-of-type(odd) {
      background-color: $snow-white;

      .dark-mode & {
        background-color: $grey-2;
      }
    }
  }

  .select {
    background-color: $teal-1 !important;
  }

  .table-header > * {
    font-weight: 600;
    background-color: $grey-2 !important;

    .dark-mode & {
      background-color: $grey-6 !important;
      color: $snow-white !important;
    }
  }

  &.crono-table-hover {
    tr:hover {
      background-color: $grey-1;
    }
  }
}

.pretty-row {
  &:hover {
    background-color: $grey-1 !important;
  }

  &.select {
    background-color: $teal-1 !important;
  }
}