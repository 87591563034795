.foods-pages {

  // Laura and Hilary asked to disable the swipe-y animation (PT#183281548)
  //.anim {
  //  transition: width 200ms linear;
  //}

  // Controls the visibility of two side-by-side components using their width so they can have a slide-y swipe-y animation
  .visible {
    width: 100%;
  }

  // Controls the visibility of two side-by-side components using their width so they can have a slide-y swipe-y animation
  .hidden {
    width: 0;
  }

  .bg-carby-blue {
    background-color: $carbs;
  }

  .bg-protein-green {
    background-color: $protein;
  }

  .bg-fat-red {
    background-color: $fat;
  }

  .bg-booze-orange {
    background-color: $alcohol;
  }
}

.selected-food-tag-pill {
  margin: 0 $spacer $spacer 0;
  padding: ($spacer * 0.25) ($spacer);
  border: thin solid $jungle-green;
  color: white;
  background-color: $jungle-green;
  //border-radius: 15px;
  cursor: pointer;
}

.unselected-food-tag-pill {
  margin: 0 $spacer $spacer 0;
  padding: ($spacer * 0.25) ($spacer);
  border: thin solid $jungle-green;
  color: $jungle-green;
  cursor: pointer;
  //border-radius: 15px;
}
