.fastball {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
.fast-ball-one {
    background: $teal-2;
    animation: appearOne 6.75s infinite ease-in-out;
}

.fast-ball-two {
    background: $teal-4;
    animation: appearTwo 6.75s infinite ease-in-out;
}

.fast-ball-three {
    background: $jungle-green;
    animation: appearThree 6.75s infinite ease-in-out;
}

.fast-edit-button-padding {
    padding: 0px 16px;
}

.fast-bar-style {
    border-radius:10px; 
    height:28px;
}

.fast-schedule-button-padding {
    padding: 9px 18px !important;
}

.fast-loading-bar {
    border-radius: 10px;
    height: 28px;
    background-color: $jungle-green;
}

@keyframes appearOne {
    33%, 100% {
        background-color: $teal-2;
    }
    0% {
        background-color: white;
    }
}

@keyframes appearTwo {
    66%, 100% {
        background-color: $teal-4;
    }
    33% {
        background-color: white;
    }
    0% {
        background-color: white;
    }
}

@keyframes appearThree {
    100% {
        background-color: $jungle-green;
    }
    66% {
        background-color: white;
    }
    33% {
        background-color: white;
    }
    0% {
        background-color: white;
    }
}

.fast-right-edit-button {
    position: relative;
    left: map-get($spacers, 1);
}

.fast-left-edit-button {
    position: relative;
    right: map-get($spacers, 1);
}
