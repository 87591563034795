
.nutrient-tab-panel {

  width: 100%;

  .gwt-TabBarItem {
    cursor: pointer;
    margin: 0 8px 16px 8px;
    color: $teal-3;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

  .gwt-TabBarItem-selected {
    border-bottom: 1px solid $crono-orange;
    color: $jungle-green;
  }

  .tabMiddleCenterInner {
    box-sizing: content-box;
    padding: 0 16px;
  }
}