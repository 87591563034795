// These colors will be available as custom css properties
// Usage: background-color: var(--crono-orange);
// Colors defined in _variables.scss

:root {
  //---------------------------------------------
  // 1. Cronometer default colours
  //---------------------------------------------

  --crono-orange: #{$crono-orange};
  --dark-raisin: #{$dark-raisin};
  --white: #{$white};
  --snow-white: #{$snow-white};
  --floral-white: #{$floral-white};
  --honey-gold: #{$honey-gold};
  --sky-blue: #{$sky-blue};
  --jungle-green: #{$jungle-green};
  --jade-lake: #{$jade-lake};
  --lupine-purple: #{$lupine-purple};
  --fire-weed: #{$fire-weed};

  //---------------------------------------------
  // 2. Cronometer tints and shades
  //---------------------------------------------

  // Greyscale
  --grey-1: #{$grey-1};
  --grey-2: #{$grey-2};
  --grey-3: #{$grey-3};
  --grey-4: #{$grey-4};
  --grey-5: #{$grey-5};
  --grey-6: #{$grey-6};
  --grey-7: #{$grey-7};
  --grey-8: #{$grey-8};
  --grey-9: #{$grey-9};

  // Orange
  --orange-1: #{$orange-1};
  --orange-2: #{$orange-2};
  --orange-3: #{$orange-3};
  --orange-4: #{$orange-4};
  --orange-5: #{$orange-5};
  --orange-6: #{$orange-6};
  --orange-7: #{$orange-7};
  --orange-8: #{$orange-8};
  --orange-9: #{$orange-9};
  --orange-10: #{$orange-10};

  // Yellow
  --yellow-1: #{$yellow-1};
  --yellow-2: #{$yellow-2};
  --yellow-3: #{$yellow-3};
  --yellow-4: #{$yellow-4};
  --yellow-5: #{$yellow-5};
  --yellow-6: #{$yellow-6};
  --yellow-7: #{$yellow-7};
  --yellow-8: #{$yellow-8};
  --yellow-9: #{$yellow-9};
  --yellow-10: #{$yellow-10};

  // Blue
  --blue-1: #{$blue-1};
  --blue-2: #{$blue-2};
  --blue-3: #{$blue-3};
  --blue-4: #{$blue-4};
  --blue-5: #{$blue-5};
  --blue-6: #{$blue-6};
  --blue-7: #{$blue-7};
  --blue-8: #{$blue-8};
  --blue-9: #{$blue-9};
  --blue-10: #{$blue-10};

  // Teal
  --teal-1: #{$teal-1};
  --teal-2: #{$teal-2};
  --teal-3: #{$teal-3};
  --teal-4: #{$teal-4};
  --teal-5: #{$teal-5};
  --teal-6: #{$teal-6};
  --teal-7: #{$teal-7};
  --teal-8: #{$teal-8};
  --teal-9: #{$teal-9};
  --teal-10: #{$teal-10};

  // Green
  --green-1: #{$green-1};
  --green-2: #{$green-2};
  --green-3: #{$green-3};
  --green-4: #{$green-4};
  --green-5: #{$green-5};
  --green-6: #{$green-6};
  --green-7: #{$green-7};
  --green-8: #{$green-8};
  --green-9: #{$green-9};
  --green-10: #{$green-10};

  // Purple
  --purple-1: #{$purple-1};
  --purple-2: #{$purple-2};
  --purple-3: #{$purple-3};
  --purple-4: #{$purple-4};
  --purple-5: #{$purple-5};
  --purple-6: #{$purple-6};
  --purple-7: #{$purple-7};
  --purple-8: #{$purple-8};
  --purple-9: #{$purple-9};
  --purple-10: #{$purple-10};

  // Pink
  --pink-1: #{$pink-1};
  --pink-2: #{$pink-2};
  --pink-3: #{$pink-3};
  --pink-4: #{$pink-4};
  --pink-5: #{$pink-5};
  --pink-6: #{$pink-6};
  --pink-7: #{$pink-7};
  --pink-8: #{$pink-8};
  --pink-9: #{$pink-9};
  --pink-10: #{$pink-10};

  //---------------------------------------------
  // 3. Non-Cronometer Defaults
  //---------------------------------------------

  --crono-error: #{$crono-error};

  //---------------------------------------------
  // 4. Container Colours
  //---------------------------------------------

  --raised-container-bg-dark: #{$raised-container-bg-dark};
}




  