.popup-menu {
  border: 1px solid $grey-2;
  border-radius: 10px;
  box-shadow: rgba($grey-6, 0.2) 0 3px 20px;
  background: $snow-white;
  font-weight: bold;

  .dark-mode & {
    background: $modal-dark-mode-bg;
    border: 1px solid $modal-dark-mode-border;
    box-shadow: 0 3px 20px $grey-8;
  }

  .popup-menu-item {
    cursor: pointer;
    padding: 8px 16px;

    &:hover {
      background-color: $grey-1;
    }

    &:active {
      background-color: $teal-1;
    }
  }


  // popup item background color overflows without these, apparent in non-white bgs
  div table tbody tr:first-child .popup-menu-item {
    border-top-left-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;
  }

  div table tbody tr:last-child .popup-menu-item {
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
  }

  .popup-menu-item-disabled {
    color: $grey-4;
    padding: 8px 16px;
    cursor: default;
  }

  hr {
    border-color: $grey-2;
    width: 100%;
    margin: 0 0;
    height: 1px;
  }
}