.style-guide-logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.style-guide-code {
  font-size: 12px;
  font-family: var(--bs-font-monospace) !important;
  background: var(--grey-1);
  padding: 2px 4px;
  margin: 0 0 10px 0;
  border-radius: 4px;
  color: var(--grey-4);

  .dark-mode & {
    background: var(--grey-8);
  }
}
