.button-base {
  width: fit-content;
  padding: 9px 40px;
  border-radius: 10px;
  border: solid 1px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 2px 8px $grey-6;
  }

  &:disabled {
    pointer-events: none;
  }
}

@mixin button-flat($primary, $secondary, $active, $disabled) {

  @extend .button-base;

  @include button-variant(
    // background, border, text
    // default
                  $primary, $primary, $secondary,
            // hover
                  $primary, $primary, $secondary,
            // active
                  $active, $active, $secondary,
            // disabled
                  $disabled, $disabled, $grey-6
  )
}

@mixin button-outline($accent, $disabled) {

  @extend .button-base;

  @include button-variant(
    // background, border, text
    // default
                  $snow-white, $accent, $accent,
            // hover
                  $snow-white, $accent, $accent,
            // active
                  $grey-1, $accent, $accent,
            // disabled
                  $snow-white, $disabled, $disabled
  )
}

@mixin button-borderless($primary, $disabled) {

  // not predicated on button-base mixin

  width: fit-content;
  padding: 9px 16px;
  border-radius: 10px;
  background: none;
  border: $primary 0;
  font-weight: bold;
  text-transform: uppercase;
  color: $primary;

  &:hover {
    color: $primary;
    background: $grey-1;
  }

  &:active {
    background: $grey-2;
  }

  &:disabled {
    pointer-events: none;
    color: $disabled;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
  }

}

.btn-flat-jungle-green {
  @include button-flat($jungle-green, $white, $teal-6, $teal-1);

  .dark-mode & {
    @include button-flat($white, $jungle-green, $grey-2, $grey-1);
  }
}

.btn-flat-crono-orange {
  @include button-flat($crono-orange, $dark-raisin, $orange-6, $orange-1);
}

.btn-flat-crono-orange-white-text {
  @include button-flat($crono-orange, $white, $orange-6, $orange-1);
}

.btn-flat-alert {
  @include button-flat($orange-7, $white, $orange-6, $orange-1);
}

.btn-flat-emerald-lake {
  @include button-flat($jade-lake, $dark-raisin, $green-6, $green-1);
}

.btn-flat-alert {
  @include button-flat($orange-7, $white, $orange-6, $orange-1);
}

.btn-flat-dark-raisin {
  @include button-flat($dark-raisin, $white, $grey-9, $grey-1);
}

.btn-outline-jungle-green {
  @include button-outline($jungle-green, $teal-2);
}

.btn-outline-dark-raisin {
  @include button-outline($dark-raisin, $grey-5);
}

.btn-outline-alert {
  @include button-outline($orange-7, $grey-5);
}

.btn-outline-crono-orange {
  @include button-outline($crono-orange, $grey-5);

  &:disabled {
    border-color: rgba($crono-orange, 0.5);
    color: rgba($crono-orange, 0.5);
  }
}

.btn-borderless-jungle-green {
  @include button-borderless($jungle-green, $teal-2)
}

.btn-borderless-dark-raisin {
  @include button-borderless($dark-raisin, $grey-5);
}

.btn-borderless-crono-orange {
  @include button-borderless($crono-orange, $grey-5);
}

.btn-borderless-alert {
  @include button-borderless($orange-7, $grey-5);
}

.btn-flat-honey-gold {
  @include button-flat($honey-gold, $dark-raisin, $yellow-6, $yellow-1);
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem !important;
  border-radius: 0.25rem !important;
}

.text-transform-none {
  text-transform: none;
}