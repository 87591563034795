// color defined classes

// creates basic color classes for gwt to access
// currently: text-color, background color
// ex: passing "jungle-green" will create .jungle-green, .bg-jungle-green, and .border-jungle-green
@mixin create-color-classes($color-name, $color) {

  $color-class: "." + $color-name;
  #{$color-class} {
    color: $color !important;
  }

  $bg-class: ".bg-" + $color-name;
  #{$bg-class} {
    background-color: $color !important;
  }

  $bg-class-50: ".bg-" + $color-name + "-50";
  #{$bg-class-50} {
    background-color: rgba($color, 0.5) !important;
  }

  $bg-class-75: ".bg-" + $color-name + "-75";
  #{$bg-class-75} {
    background-color: rgba($color, 0.75) !important;
  }

  $border-class: ".border-" + $color-name;
  #{$border-class} {
    border-color: $color !important;
  }

  $hover-class: ".hover-" + $color-name;
  #{$hover-class} {
    &:hover {
      color: $color !important;
    }
  }

  $background-hover-class: ".bg-hover-" + $color-name;
  #{$background-hover-class} {
    &:hover {
      background-color: $color !important;
    }
  }

  @each $size-name, $size in $spacers {
    $hatched-bg-class: ".hatched-" + $color-name + "-" + $size-name;
    #{$hatched-bg-class} {
      background-image: repeating-linear-gradient(
                      -45deg,
                      $color,
                      $color $size,
                      transparent $size,
                      transparent 2 * $size
      ) !important;
    }
  }
}

// light and dark
@include create-color-classes("light", $floral-white);
@include create-color-classes("dark", $grey-9);

// basic colors
@include create-color-classes("crono-orange", $crono-orange);
@include create-color-classes("dark-raisin", $dark-raisin);
@include create-color-classes("white", $white);
@include create-color-classes("snow-white", $snow-white);
@include create-color-classes("floral-white", $floral-white);
@include create-color-classes("honey-gold", $honey-gold);
@include create-color-classes("sky-blue", $sky-blue);
@include create-color-classes("jungle-green", $jungle-green);
@include create-color-classes("jungle-green-faded", $teal-1);
@include create-color-classes("jade-lake", $jade-lake);
@include create-color-classes("lupine-purple", $lupine-purple);
@include create-color-classes("fire-weed", $fire-weed);
@include create-color-classes("crono-error", $crono-error);

// @see https://xd.adobe.com/view/e3bbf37d-9ba7-4866-afe8-8a0b08abd3b5-b6e8/screen/39a3792d-11de-4491-8acb-a2a27c985adb/specs/
@include create-color-classes("energy", $grey-5);
@include create-color-classes("energy-faded", $grey-3);
@include create-color-classes("carbs", $sky-blue);
@include create-color-classes("carbs-faded", $blue-2);
@include create-color-classes("protein", $jade-lake);
@include create-color-classes("protein-faded", $green-2);
@include create-color-classes("fat", $orange-7);
@include create-color-classes("fat-faded", $orange-2);
@include create-color-classes("exercise", $yellow-7);
@include create-color-classes("tef", $yellow-9);
@include create-color-classes("activity", $teal-4);
@include create-color-classes("bmr", $lupine-purple);
@include create-color-classes("fasting", $blue-8);
@include create-color-classes("alcohol", $honey-gold);
@include create-color-classes("nutrition-scores", $pink-5);

// shades & tints

@include create-color-classes("grey-1", $grey-1);
@include create-color-classes("grey-2", $grey-2);
@include create-color-classes("grey-3", $grey-3);
@include create-color-classes("grey-4", $grey-4);
@include create-color-classes("grey-5", $grey-5);
@include create-color-classes("grey-6", $grey-6);
@include create-color-classes("grey-7", $grey-7);
@include create-color-classes("grey-8", $grey-8);
@include create-color-classes("grey-9", $grey-9);

@include create-color-classes("orange-1", $orange-1);
@include create-color-classes("orange-2", $orange-2);
@include create-color-classes("orange-3", $orange-3);
@include create-color-classes("orange-4", $orange-4);
@include create-color-classes("orange-5", $orange-5);
@include create-color-classes("orange-6", $orange-6);
@include create-color-classes("orange-7", $orange-7);
@include create-color-classes("orange-8", $orange-8);
@include create-color-classes("orange-9", $orange-9);
@include create-color-classes("orange-10", $orange-10);

@include create-color-classes("yellow-1", $yellow-1);
@include create-color-classes("yellow-2", $yellow-2);
@include create-color-classes("yellow-3", $yellow-3);
@include create-color-classes("yellow-4", $yellow-4);
@include create-color-classes("yellow-5", $yellow-5);
@include create-color-classes("yellow-6", $yellow-6);
@include create-color-classes("yellow-7", $yellow-7);
@include create-color-classes("yellow-8", $yellow-8);
@include create-color-classes("yellow-9", $yellow-9);
@include create-color-classes("yellow-10", $yellow-10);

@include create-color-classes("blue-1", $blue-1);
@include create-color-classes("blue-2", $blue-2);
@include create-color-classes("blue-3", $blue-3);
@include create-color-classes("blue-4", $blue-4);
@include create-color-classes("blue-5", $blue-5);
@include create-color-classes("blue-6", $blue-6);
@include create-color-classes("blue-7", $blue-7);
@include create-color-classes("blue-8", $blue-8);
@include create-color-classes("blue-9", $blue-9);
@include create-color-classes("blue-10", $blue-10);

@include create-color-classes("teal-1", $teal-1);
@include create-color-classes("teal-2", $teal-2);
@include create-color-classes("teal-3", $teal-3);
@include create-color-classes("teal-4", $teal-4);
@include create-color-classes("teal-5", $teal-5);
@include create-color-classes("teal-6", $teal-6);
@include create-color-classes("teal-7", $teal-7);
@include create-color-classes("teal-8", $teal-8);
@include create-color-classes("teal-9", $teal-9);
@include create-color-classes("teal-10", $teal-10);

@include create-color-classes("green-1", $green-1);
@include create-color-classes("green-2", $green-2);
@include create-color-classes("green-3", $green-3);
@include create-color-classes("green-4", $green-4);
@include create-color-classes("green-5", $green-5);
@include create-color-classes("green-6", $green-6);
@include create-color-classes("green-7", $green-7);
@include create-color-classes("green-8", $green-8);
@include create-color-classes("green-9", $green-9);
@include create-color-classes("green-10", $green-10);

@include create-color-classes("purple-1", $purple-1);
@include create-color-classes("purple-2", $purple-2);
@include create-color-classes("purple-3", $purple-3);
@include create-color-classes("purple-4", $purple-4);
@include create-color-classes("purple-5", $purple-5);
@include create-color-classes("purple-6", $purple-6);
@include create-color-classes("purple-7", $purple-7);
@include create-color-classes("purple-8", $purple-8);
@include create-color-classes("purple-9", $purple-9);
@include create-color-classes("purple-10", $purple-10);

@include create-color-classes("pink-1", $pink-1);
@include create-color-classes("pink-2", $pink-2);
@include create-color-classes("pink-3", $pink-3);
@include create-color-classes("pink-4", $pink-4);
@include create-color-classes("pink-5", $pink-5);
@include create-color-classes("pink-6", $pink-6);
@include create-color-classes("pink-7", $pink-7);
@include create-color-classes("pink-8", $pink-8);
@include create-color-classes("pink-9", $pink-9);
@include create-color-classes("pink-10", $pink-10);