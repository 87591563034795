.suggest-food-dialog {
  margin: 6px 0 0 0;

  .row-container {
    display: flex;
    flex-direction: row;
    padding: 16px;
  }

  .row-container:nth-child(even) {
    background: $grey-1;
  }

  .pie-chart-container {
    height: 70px;
    position: relative;
    margin-right: 16px;

    .center-background {
      position: relative;
      left: 6px;
      top: -66px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background: $white;
    }

    .circle-target {
      position: absolute;
      top: -7px;
      left: 9px;
    }

    .circle-target-percent {
      margin: 11px 0 0 0;
      font-size: 15px;
    }

    .circle-target-inner-info {
      width: 45px;
      height: 45px;
    }
  }

  .suggest-food-labels-container {
    width: 512px;
  }

  .suggest-food-title-container {
    display: flex;
    flex-direction: column;
  }

  .suggest-food-measure {
    font-size: 14px;
  }

  .suggest-food-nutrients {
    font-size: 14px;
    display: flex;
    flex-direction: row;
  }

  .suggest-food-like,
  .suggest-food-dislike {
    color: $grey-6;

    span {
      font-size: 36px;
    }
  }

  .suggest-food-liked,
  .suggest-food-like:hover {
    color: $jade-lake !important;
  }

  .suggest-food-disliked,
  .suggest-food-dislike:hover {
    color: $orange-7 !important;
  }

  .suggest-food-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
  }

  .fade-animate {
    animation: fadeOut 0.1s steps(60) forwards;
    -webkit-animation: fadeOut 0.1s steps(60) forwards;
    -moz-animation: fadeOut 0.1s steps(60) forwards;
  }
}
