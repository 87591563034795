.ab-feed, .ab-card, .ab-title {
  font-family: $font-family-base !important;
}

.ab-feed-buttons-wrapper {
  background-color: $dark-raisin !important;
}

.ab-feed {
  background-color: $snow-white !important;
}

.ab-card {
  border-radius: 10px !important;
  background-color: $white !important;
}

.ab-url-area {
  color: $jungle-green !important;
  font-weight: 800 !important;
}

.ab-close-button {
  opacity: 1 !important;
  filter: brightness(0) saturate(100%) invert(23%) sepia(91%) saturate(647%) hue-rotate(137deg) brightness(98%) contrast(101%) !important;

  &.fa.fa-times {
    filter: none !important;
  }
}