.collapsible-section i.rotate {
  transition: transform 150ms ease-in-out;
  transform-origin: .5em 50%;
  transform: rotate(0deg);
}

/* This selection based on the 'aria-expanded' attribute is piggybacking off of Bootstrap's expandable area utility (search those docs
 for more info about this otherwise odd-looking design decision). */
.collapsible-section[aria-expanded="true"] i.rotate {
  transform: rotate(180deg);
}