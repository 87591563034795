.add-biometric-dialog {
  .search-icon {
    width: 16px;
    font-size: 21px;
    position: absolute;
    padding-top: 7px;
    padding-left: 24px;
  }

  .gate-custom {
    cursor: pointer;
    vertical-align: middle;
    padding: 0 10px;
    margin-bottom: 3px;
  }

  .add-custom-biometric {
    width: fit-content;
    z-index: 1010;
    position: absolute;
    right: 0;
    top: 18px;
    cursor: pointer;
  }

  .custom-biometric-icon {
    font-size: 24px;
  }

  .stool-img {
    width: 50px;
    height: 50px;
  }

  .border-radius {
    border-radius: 10px;
    padding: 16px 24px;
  }

  .scroll-panel {
    border-radius: 10px;
    padding: 32px
  }
}

.stools-biometric {
  .bristol-guide-txt {
    cursor: pointer;
    text-decoration: underline;
  }
}

.biometric-diary-title {
  .name {
    display: inline;
  }

  .wrapper {
    display: inline;
  }

  .showMore {
    display: inline;
    font-size: 12px;
    font-weight: bold;
    padding: 0 5px;
  }

  .subtitle {
    font-size: 11px;
    display: inline;
    margin-left: 10px;
    margin-right: 5px;
    color: #404040;
  }

  .name-subtitle {
    display: inline;
    color: #5B5D6B;
  }

}

.biometric-more-detail-dialog {
  .main-panel {
    padding: 20px 0;
    text-align: center;
  }

  .charts-panel {
    border: 1px solid #C3C6D2;
    border-radius: 20px;
  }

  .statsArea {
    border: 1px solid #C3C6D2;
    border-radius: 20px;
  }

  .statsLabel {
    text-align: left;
    color: $dark-raisin;
    font-size: 22px;
    font-family: "Work Sans";
    font-weight: bold;
  }

  .ok {
    float: right;
    margin-top: 35px;
    margin-bottom: 25px;
  }
}

.biometric-samples-chart {
  .panel {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    background: #fff;
    padding: .5em
  }

  .timeBox {
    vertical-align: middle;
    display: inline-block;
  }

  .options-panel {
    display: inline-block;
    z-index: 2;
    width: 100%;
    height: 80px;
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    box-sizing: border-box;
    margin: 0;
  }

  .boxes {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
  }

  .boxes select {
    width: 220px;
  }


  .unitBoxes select {
    width: 107px;
    vertical-align: middle;
  }


  .option-container {
    display: inline-block;
    float: right;
  }

  .spinner {
    margin: auto;
    width: 50px;
  }
}

.biometric-content-panel {
  .border-radius-10 {
    border-radius: 10px;
  }

  .biometric-radius-11 {
    border-radius: 11px;
  }
}