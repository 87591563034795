.adv-rec-s-s-ed {
  /**
  Magic number '50px' below perfectly fits 1.5 text characters in the box, the minimum to make the UI passable.
   */
  .amt-col {
    flex: 1 0 50px;
    min-width: 50px;
  }

  /**
    Magic number '108px' below perfectly fits the column title "Serving Name" without wrapping.
   */
  .name-col {
    flex: 3 0 108px;
    min-width: 108px;
  }

  /**
    Magic number '153px' below perfectly fits the column title "Servings per Recipe" (the widest possible title for this column) without
    wrapping.
   */
  .val-col {
    flex: 3 0 153px;
    min-width: 153px;
  }
}