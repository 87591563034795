.selector-large {
    display: flex;
    align-items: center;

    &.disabled-input {
        pointer-events: none;

        .daySelectionBox {
            border: solid $grey-2 1px;
            color: $grey-6;
        }

        .selectedDay {
            color: $grey-6;
            background-color: $teal-1;
        }

        .checkbox-container {
            color: $grey-6;
        }
    }

    .daySelectionBox {
        border-radius: 32px;
        margin: 0 24px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        height: 80px;
        background-color: $snow-white;
        border: solid $grey-4 1px;
        cursor: pointer;

        .title {
            font-weight: bold;
            font-size: 22px;
            padding-top: 2px;
        }

        .sub-title {
            padding-top: 10px;
        }
    }

    .checkbox-container {
        display: flex;
        .checkbox-label {
            font-weight: 600;
            margin-right: 16px;
        }
    }

    .selectedDay {
        background-color: $jungle-green;
        color: white;
        border: solid $jungle-green 1px;
    }
}

.selector-small {
    .selectedDay {
        background-color: $crono-orange;
        color: white;
    }

    .unSelectedDay {
        background-color: white;
        color: $crono-orange;
    }

    .daySelectionBox {
        border: 3px solid $crono-orange;
        border-radius: 5px;
        font-weight: bold;
        margin-right: 15px;
        font-size: 16px;
        font-family: "Manrope", monospace;
        cursor: pointer;
        height: 28px;
        width: 28px;
        display: inline-block;
        text-align: center;
    }

    .sub-title {
        display: none;
    }
}
.repeatIntervalLabel {
    margin: auto 16px;
}