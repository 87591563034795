.redeem-page {
    /** WEB SIZE **/
    @include media-breakpoint-up(xl) {
        .crono-main-content-container {
            display: flex;
            flex-direction: row-reverse;
        }

        .crono-text-content-container {
            position: relative;
            flex: 0 0 465px;
            margin-left: 96px;
            padding-top: calc(0.5 * 22vh);
        }

        .header-logo {
            display: block;
            margin: 12px auto;
            max-width: 480px;
        }

        .crono-splash-graphic {
            flex: 1 1 auto;
        }

        .crono-splash-graphic > img {
            display: block;
            height: calc(100vmin - 200px);
            width: 90%;
            object-fit: contain;
            margin: 0px auto;
        }

        .conditions {
            position: absolute;
            bottom: 0px;
        }

        .crono-butt-holder {
            margin-top: map-get($spacers, 5) !important;
            display: block;
        }
    }

    /** TABLET SIZE **/
    @include media-breakpoint-between(md, xl) {
        .crono-main-content-container {
            display: flex;
            flex-direction: column;
        }

        .header-logo {
            display: block;
            margin: 12px auto;
            max-width: 480px;
        }

        .crono-splash-graphic {
            flex: 0 0 auto;
        }

        .crono-splash-graphic > img {
            display: block;
            width: 90%;
            margin: 24px auto;
        }

        .crono-text-content-container {
            flex: 0 0 auto;
            margin: 0 auto map-get($spacers, 6) auto;
            font-size: 22px;
            font-weight: 100;
            text-align: left;
        }

        .crono-text-content-container > span {
            display: block;
            width: 500px;
            margin: 0px 0px map-get($spacers, 3) 0px;
        }

        .crono-butt-holder {
            margin-top: map-get($spacers, 3) !important;
            font-size: 16px;
        }

        .conditions {
            position: unset;
        }

        .crono-butt-holder {
            margin-top: map-get($spacers, 3);
        }

        .conditionHolder {
            width: 500px;
            margin: map-get($spacers, 6) 0px map-get($spacers, 3) 0px;
        }
    }

    .largeDarkRaisinText {
        color: $dark-raisin;
        font-size: 24px;
        font-weight: 600;
        font-family: $font-family-base;
    }

    .lineBreak {
        margin-top: map-get($spacers, 5);
        width: 100%;
        border: 1px #c3c6d2 solid;
    }

    /** MOBILE SIZE **/
    @include media-breakpoint-down(md) {
        .crono-main-content-container {
            display: flex;
            flex-direction: column;
        }

        .header-logo {
            display: block;
            margin: 12px auto;
            max-width: 354px;
        }

        .crono-splash-graphic {
            flex: 0 0 auto;
        }

        .crono-splash-graphic > img {
            display: block;
            width: 90%;
            margin: 24px auto;
        }

        .crono-text-content-container {
            flex: 0 0 auto;
            max-width: 380px;
            margin: 0px auto map-get($spacers, 5) auto;
        }

        .crono-text-content-container > span {
            display: block;
            width: 400px;
            margin: 0 auto 25px auto;
        }

        .crono-butt-holder {
            margin-bottom: 70px;
            font-size: 18px;
        }

        .topBoldText {
            padding-right: 100px;
        }

        .largeDarkRaisinText {
            font-size: 18px;
        }

        .lineBreak {
            width: 90%;
        }

        .conditionHolder {
            width: 400px;
            margin: map-get($spacers, 6) auto map-get($spacers, 3) auto;
        }

        .crono-butt-holder {
            margin-top: map-get($spacers, 4) !important;
        }

        .cron-gold-text {
            display: none !important;
        }

        .feature-holder {
            font-size: 14px !important;
        }

        .container-description {
            font-size: 14px !important;
            padding-right: 25px !important;
        }
    }

    .redeemText {
        margin-top: map-get($spacers, 3);
        display: inline-block;
    }

    .descriptionText {
        padding-top: 32px;
    }

    .toBeginText {
        margin-top: map-get($spacers, 5) !important;
        display: inline-block !important;
    }

    .toBeginTextDialog {
        font-size: 18px !important;
        display: inline-block !important;
    }

    .viewAllFeatures {
        display: inline-block;
        font-family: $font-family-base;
        color: $jungle-green;
        font-weight: 800;
        font-size: 16px;
        cursor: pointer;
        margin-top: map-get($spacers, 5);
    }

    /** MOBILE SIZE **/
    @include media-breakpoint-down(sm) {
        .crono-text-content-container > span {
            width: 350px;
        }

        .header-logo {
            display: block;
            margin: 12px auto;
            max-width: 354px;
        }

        .lineBreak {
            margin-top: unset;
            width: 90%;
        }

        .toBeginText {
            display: block !important;
        }

        .conditionHolder {
            width: 350px;
        }

        .header-logo {
            max-width: 354px;
        }
    }

    .crono-text-content-container {
        position: relative;
    }

    .smallDarkRaisinText {
        font-family: $font-family-base;
        font-size: 16px;
    }

    .topBoldText {
        font-family: "Work Sans", sans-serif;
        font-size: 36px;
        font-weight: bold;
    }

    .plans-panel .checks {
        display: block;
    }

    .header {
        margin-bottom: 0px !important;
    }

    .modal-header-div {
        display: inline-block;
        vertical-align: middle;
    }

    .modal-close {
        position: relative;
        bottom: 20px;
        left: 10px;
    }

    .modal-border {
        border-radius: $border-radius-sm;
        border: 1px solid $grey-2;
    }

    .first-element-modal { 
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;
    }

    .cronometer-gold-text-label {
        flex-grow: 1; 
        text-align: right;
    }
}
