.warning-container {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  padding: 16px 32px;
  margin: 32px 0;
  background-color: $yellow-1;
  border-radius: 10px;
}

.clients-panel {
  margin: 32px 0 0 0;

  .add-container {
    display: flex;
    flex-direction: row;

    .add-group-button {
      display: flex;
      align-content: center;
      align-items: center;

      span,
      i {
        margin-right: 8px;
      }
    }
  }

  .action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0;

    .search {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;

      i {
        font-size: 28px;
        margin-right: 16px;
      }
    }
  }

  .client-list {
    .dark-mode & {
      border: 1px solid $grey-7;
    }
  }

  .pro-client-list {

    tr.dragging td {
      cursor: move !important;
      border-top: 2px solid $grey-6;
      border-bottom: 2px solid $grey-6;
    }

    .client-list-header td {
      padding: 20px 0 20px 16px;
      border-bottom: 2px solid $grey-3;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .client-list-header td:first-child {
      border-top-left-radius: 10px;
    }

    .client-list-header td:last-child {
      border-top-right-radius: 10px;
    }

    .client-group {
      background: $snow-white;
      border-bottom: 1px solid $grey-3;
    }

    .client-group-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 26px 12px 8px;

      .title-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
      }

      .client-group-action {
        display: none;
        margin-left: 16px;
        color: $jungle-green;
        cursor: pointer;
      }

      &:hover .client-group-action {
        display: block;
      }

    .icon-chevron-up {
      color: $jungle-green;
      font-size: 18px;
      font-weight: 700;
      transition: all 200ms ease-in-out;
    }

    .pro-client-list-chevron-down {
      transform: rotate(180deg);
    }

    .pro-client-list-chevron-up {
      transform: rotate(0deg);
    }
    }

    .client-row td {
      border-bottom: 1px solid $grey-3;
      padding: 8px 0px 8px 16px;

      &:not(:last-child) {
        border-right: 1px solid $grey-3;
      }

      .dark-mode & {
        background: $grey-6;
        border-bottom: 1px solid $grey-5;
      }
    }

    td.clickable {
      cursor: pointer;

      &:hover {
        background: $grey-1;
      }
    }

    .bottom-target {
      width: 100%;
      height: 40px;
    }
  }

  .client-row {
    .material-icons-outlined {
      color: $jungle-green;
      display: flex;
      align-content: center;
      align-items: center;

      .dark-mode & {
        color: $teal-1;
      }
    }

    .icon-exclamation-circle {
      color: $danger;
      margin-left: 5px;
    }

    .icon-chevron-right {
      visibility: hidden;
    }

    td:hover .icon-chevron-right {
      visibility: visible;
    }
  }

  tr.select td {
    background-color: $teal-1;
  }
}

.drag-over-border-top {
  border-top: 4px solid $crono-orange !important;
}

.drag-over-group {
  background-color: $orange-4 !important;
}

.client-data-view {
  .client-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;

    .client-email {
      font-size: 18px;
      font-weight: 600;
    }

    .client-actions-container {
      display: flex;
      flex-direction: row;
      height: 42px;
    }
  }

  .client-note {
    width: 100%;
    padding: 16px 32px;
    margin: 16px 0;
    border-radius: 10px;
    border: 1px solid $grey-2;
  }

  .notes-container {
    border-radius: 10px;
    border: 1px solid $grey-1;

    .notes-header {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      background: $grey-3;
      padding: 8px 16px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }

    .note-time {
      width: 300px;
    }

    .note-text {
      width: 100%;
    }

    .note-container {
      display: flex;
      flex-direction: row;
      padding: 8px 16px;
      font-size: 14px;

      .icon-trash-fill {
        cursor: pointer;
        margin: 0 0 0 32px;
        color: $crono-orange;
        font-size: 24px;
      }

      .icon-trash-fill:hover {
        opacity: 0.7;
      }
    }
  }

  .note-container:nth-child(even) {
    background: $grey-1;
  }
}

.bulk-import-drag-drop-container {
  background-color: $snow-white;
  border: 1px solid $grey-2;
  border-radius: $border-radius-xl;
  padding: map-get($spacers, 4);
  padding-bottom: map-get($spacers, 3);
}

.bulk-import-dotted-container {
  border: 1px dashed var(--gray-6);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px dashed $grey-6;
  border-radius: 10px;
  opacity: 1;
}

.bulk-import-dotted-container-dragover {
  background-color: $grey-1;
}

.bulk-add-clients-table {
  @extend .crono-table;
  border-spacing: 0;

  & tr td {
    padding: 1rem;
  }

  & tr:first-child td {
    border-bottom: 1px solid $grey-2;
  }

  .select {
    background-color: $grey-2 !important;
  }
}

.bulk-add-clients-table-check-header {
  .form-check-input {
    border: 2px solid $dark-raisin;
  }

  .form-check-input:checked {
    background-color: $dark-raisin;
    border-color: $dark-raisin;
  }
}

.bulk-add-clients-table-check-item {
  .form-check-input {
    border: 2px solid $crono-orange;
  }
  
  .form-check-input:checked {
    background-color: $crono-orange;
    border-color: $crono-orange;
  }
}

.bulk-add-clients-response-dialog-container {
  border: 1px solid $grey-2;
  border-radius: $border-radius-lg;
  padding: map-get($spacers, 3)
}

.bulk-add-clients-down-chevron {
  font-size: map-get($font-sizes, 8)
}