
.nutrient-target {
  margin: 16px 0;
  text-align: center;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;

  .percent {
    margin-top: 21px;
    display: block;
    font-size: 18px;
    font-weight: 700;
  }

  .title {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }

  .no-target {
    font-size: 15px;
    line-height: 16px;
    margin-top: 17px;
  }
}

.score-ad-banner {
  box-shadow: 0 3px 6px $grey-1;
}

