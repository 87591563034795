.text-box {
  width: 100%;
  text-align: left;
  background: $snow-white;
  border: 1px solid $grey-6;
  border-radius: 4px;
  color: $dark-raisin;
  padding: 8px 16px;

  .dark-mode & {
    background: $grey-2;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($dark-raisin, .5);
  }

  &:focus-visible {
    outline: none;
  }

  .invalid-input & {
    border: 1px solid $orange-10;

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($orange-10, .5);
    }
  }

  .disabled-input & {
    color: $grey-6;
    pointer-events: none;
    border: 1px solid $grey-5;

    ::placeholder {
      color: $grey-5;
    }
  }

  .unselectable-input & {
    pointer-events: none;
  }

  .input-light & {
    background-color: $white;
  }
}

.label {
  padding-bottom: 4px;
  font-weight: 600;

  .disabled-input & {
    color: $grey-6;
  }
}

.error {
  visibility: hidden;
  padding-top: 4px;
  font-size: 14px;
  white-space: nowrap;
  color: $orange-10;

  .invalid-input & {
    visibility: visible;
  }
}

/* plain variant used for inline table edits */
.number-box {
  height: 22px;
  width: 64px;
  padding: 3px;
  font-size:12px;
  display: inline;
  border: 1px solid gray;
}
