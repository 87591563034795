
.diary-date-nav {
  display: inline-flex;
  align-items: center;
}

.diary-date-completed {
  display: flex;
  cursor: pointer;

  .material-icons,
  .material-icons-outlined {
    color: $jungle-green;
    font-size: 28px;
    border-radius: 100px;
    width: 38px;
    padding: 6px;
    margin-right: 16px;

    .dark-mode & {
      color: $teal-2;
    }
  }

  .material-icons:hover,
  .material-icons-outlined:hover {
    background: $grey-1;

    .dark-mode & {
      background: $grey-8;
    }
  }
}

.diary-date-btn-container {
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: inherit;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 10px;
  width: 100%;

  i {
    color: $jungle-green;
    margin-right: 16px;
    font-size: 20px;

    .dark-mode & {
      color: $teal-2;
    }
  }
}

.diary-date-btn-container:hover {
  background: $grey-1;

  .dark-mode & {
    background: $grey-8;
  }
}

.diary-date-btn {
  width: max-content;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.diary-date-previous,
.diary-date-next {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-family: sans-serif;
  width: 10%;
  height: 100%;
  color: $jungle-green;
  padding: 10px 8px;

  .dark-mode & {
    color: $teal-2;
  }
}

.diary-date-previous:hover,
.diary-date-next:hover {
  border-radius: 10px;
  background-color: $grey-1;

  .dark-mode & {
    background-color: $grey-8;
  }
}

.date-picker-modal {
  padding: 32px;
  background: $snow-white;
  border-radius: 10px;
  box-shadow: 0 3px 20px #7779861A;
  border: 1px solid $grey-3;

  .dark-mode & {
    background: $grey-8;
    border: 1px solid $grey-7;
  }
}

.date-picker-modal-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0 0 16px 0;
  font-weight: 700;
}

.date-picker-modal-header i {
  font-size: 22px;
}

.date-picker-modal-header i:hover {
  color: $grey-6;
}

.date-picker-modal-fasting {
  font-size: map-get($font-sizes, 7);
}

.date-picker-modal-fasting-line {
  border-radius: 10px;
  height: $fast-line-height;
  width: 43px;
  background-color: $blue-8;
  margin: 10px 10px 0 0;
}

.date-picker-modal-follicular-line {
  border-radius: 10px;
  height: $fast-line-height;
  width: 43px;
  background-color: $pink-5;
  margin: 10px 10px 0 0;
}

.date-picker-modal-luteal-line {
  border-radius: 10px;
  height: $fast-line-height;
  width: 43px;
  background-color: $blue-5;
  margin: 10px 10px 0 0;
}